import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from '@/components/ui/button';
import { buttonVariants } from '@/components/ui/button';
import { Form, FormControl, FormItem, FormMessage } from '@/components/ui/form';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
// import { toast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';

const FormSchema = z.object({
  dob: z.date({
    required_error: 'A date of birth is required.',
  }),
});

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  onSelect?: (date: Date | undefined) => void;
  selected?: Date | undefined; // Add selected prop
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  onSelect,
  selected,
  ...props
}: CalendarProps) {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date());

  useEffect(() => {
    if (
      selected &&
      selected.getFullYear() !== selectedYear &&
      selectedYear !== new Date().getFullYear()
    ) {
      // Only update if the selected year differs
      const newDate = new Date(selected);
      newDate.setFullYear(selectedYear); // Set the selected date to the new year
      setMonth(newDate); // Update month to the new date with the correct year
      onSelect?.(newDate); // Update selectedDate in the parent
    } else if (!selected) {
      // Only update month based on selectedYear if there's no selected date
      setMonth(new Date(selectedYear, month.getMonth()));
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selected) {
      setMonth(selected); // Set the month to the selected date if it exists
      setSelectedYear(selected.getFullYear()); // Set the year to the selected date's year
    } else {
      setMonth(new Date(selectedYear, month.getMonth()));
    }
  }, [selected, selectedYear]);
  return (
    <div className="relative">
      <div className="absolute top-0 flex w-full justify-center">
        <div className="z-10 mt-[10px] bg-white">
          <span className="mr-2">{format(month, 'MMMM')}</span>
          <select
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(Number(e.target.value));
            }}
            className="z-40 rounded border p-1"
          >
            {Array.from({ length: 200 }, (_, i) => new Date().getFullYear() - 100 + i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ),
            )}
          </select>
        </div>
      </div>
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={cn('p-3', className)}
        classNames={{
          months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4',
          caption: 'flex justify-center pt-1 relative items-center',
          caption_label: 'text-sm font-medium',
          nav: 'space-x-1 flex items-center',
          nav_button: cn(
            buttonVariants({ variant: 'outline' }),
            'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          ),
          nav_button_previous: 'absolute left-1',
          nav_button_next: 'absolute right-1',
          table: 'w-full border-collapse space-y-1',
          head_row: 'flex',
          head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
          row: 'flex w-full mt-2',
          cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            buttonVariants({ variant: 'ghost' }),
            'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
          ),
          day_range_end: 'day-range-end',
          day_selected:
            'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          day_today: 'bg-accent text-accent-foreground',
          day_outside:
            'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          day_disabled: 'text-muted-foreground opacity-50',
          day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
          day_hidden: 'invisible',
          ...classNames,
        }}
        components={{
          IconLeft: ({ ...props }) => <ChevronLeft className="size-4" />,
          IconRight: ({ ...props }) => <ChevronRight className="size-4" />,
        }}
        month={month}
        selected={selected} // Use selected prop passed from CalendarForm
        onSelect={(date) => {
          console.log({ selectedDate: date }, 'SELECTED1');
          onSelect?.(date); // Call the onSelect prop if provided
        }}
        onMonthChange={setMonth}
        {...props}
      />
    </div>
  );
}
Calendar.displayName = 'Calendar';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  control: any;
  underText?: string;
  isError?: any;
  required?: boolean;
  callOnBlur?: (input) => void;
}

const CalendarForm = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      control,
      underText,
      isError,
      required = false,
      callOnBlur = (input) => {
        console.log('INPUT BLUR', input);
      },
    },
    ref,
  ) => {
    const form = useForm<z.infer<typeof FormSchema>>({
      resolver: zodResolver(FormSchema),
    });

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

    function onSubmit(data: z.infer<typeof FormSchema>) {
      console.log({
        title: 'You submitted the following values:',
        description: JSON.stringify(data, null, 2),
      });
    }

    function isUnixTimestamp(value) {
      const timestamp = Number(value);
      return (
        !isNaN(timestamp) &&
        (String(value).length === 10 || String(value).length === 13) &&
        new Date(timestamp).getFullYear() > 1970
      );
    }

    return (
      <div className="relative">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <Controller
              control={control}
              name={name}
              rules={{ required }}
              render={({ field }) => (
                <FormItem className="flex flex-col" key={`${field?.value}`}>
                  {label && label?.length > 0 && <p className="mb-1 text-sm opacity-80">{label}</p>}
                  <Popover>
                    <PopoverTrigger asChild className="h-auto">
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn(
                            `w-full px-[16px] py-[13px] text-left font-normal capitalize ${
                              isError ? 'border-red-500' : ''
                            }`,
                            !field.value && 'text-muted-foreground',
                          )}
                        >
                          {selectedDate ? (
                            <>
                              {isUnixTimestamp(selectedDate) ? (
                                <>{format(new Date(Number(selectedDate)), 'PPP')}</>
                              ) : (
                                <>{format(selectedDate, 'PPP')}</>
                              )}
                            </>
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto size-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={selectedDate} // Pass selectedDate from CalendarForm
                        onSelect={(date) => {
                          setSelectedDate(date); // Update selectedDate in CalendarForm
                          field.onChange(date);
                          callOnBlur({ [name]: date });
                        }}
                        disabled={(date: any) => date < new Date('1900-01-01')}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  {/* <FormDescription>Your date of birth is used to calculate your age.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <p
          className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${
            isError ? 'text-red-500' : ''
          }`}
        >
          {underText}
        </p>
      </div>
    );
  },
);

CalendarForm.displayName = 'CalendarForm';

export { CalendarForm };
