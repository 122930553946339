import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import spacetime from 'spacetime';

import DefaultImage from '@/assets/DefaultImage.png';
import { cn } from '@/lib/utils';

function CompanyCard({ item, index, loading = true }) {
  const navigate = useNavigate();

  return (
    <button
      key={`card_${index}`}
      className="grid h-[205px] w-[420px] rounded-[16px] bg-card p-[22px] shadow-md transition-shadow duration-500 ease-in-out hover:shadow-lg"
      onClick={() => {
        navigate(`/security-token-view/${item.id}`);
      }}
    >
      <div className="flex">
        <img
          src={
            !loading &&
            (item?.site_builder?.icon_url?.hostedUrl ||
              item?.site_builder?.icon_url ||
              DefaultImage)
          }
          className={cn(
            'size-[6rem] min-w-[6rem] rounded-[16px] bg-secondary object-cover shadow-md',
            loading ? 'animate-pulse' : '',
          )}
        />
        <div className="relative ml-[18px] w-full">
          <h1
            className={cn(
              'relative line-clamp-1 text-left text-lg font-semibold',
              loading ? 'h-[28px] w-[100px] animate-pulse rounded-[6px]' : '',
            )}
          >
            {!loading && item.name}
          </h1>
          <p className="mt-[10px] line-clamp-2 text-left opacity-60">
            {item?.site_builder?.description}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[5px] pt-[15px] opacity-60">
        <div className="flex justify-between">
          <p>Raise Amount:</p>
          <p className={loading ? 'h-[24px] w-[100px] animate-pulse rounded-[6px]' : ''}>
            {!loading && `$${item.amount?.toLocaleString()}`}
          </p>
        </div>
        <div className="flex justify-between">
          <p>Offering Period:</p>
          <p className={loading ? 'h-[24px] w-[100px] animate-pulse rounded-[6px]' : ''}>
            {!loading &&
              `${spacetime(item.start_date).format('{month-short} {date-ordinal}')} - ${spacetime(item.closing_date).format('{month-short} {date-ordinal}')}`}
          </p>
        </div>
      </div>
    </button>
  );
}

export default CompanyCard;
