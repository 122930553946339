import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Close from '@/assets/Close.svg?react';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { useToast } from '@/components/ui/use-toast';
import config from '@/config';
import { AccessLevel, permissionNames } from '@/const';
import { UpdatePermissionDocument } from '@/lib/__generated__/dashboard/graphql';
import {
  DeleteCompanyUserDocument,
  UpdateCompanyUserDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

const TeamCard: FunctionComponent<Props> = ({
  item,
  index,
  create = false,
  onChange = (arg) => {},
  isLoading = false,
  onSelect = (arg) => {},
  update = false,
  handleRemoveItem = (index) => {},
}) => {
  const decodedToken = useBoundStore((state) => state.authSlice.decodedToken);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const { toast } = useToast();
  const isSuperUser = decodedToken?.user?.super_user;
  const my_access_level = decodedToken?.projects?.[selectedProject?.project_name]?.access_level;
  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);

  const [selectedPermission, setSelectedPermission] = useState<number | null>(
    item?.access_level ?? AccessLevel.CREATE,
  );

  const permissionsOptions = [
    { label: 'No Permission', value: 0 },
    // { label: 'Observer', value: 10 },
    { label: 'Viewer', value: 20 },
    // { label: 'Lister', value: 25 },
    { label: 'Editor', value: 30 },
    { label: 'Creator', value: 40 },
    { label: 'Admin', value: 50 },
    { label: 'Owner', value: 60 },
  ];

  const handleUpdateTeam = async (selectedPerm, deleteUser = false) => {
    try {
      console.log({ selectedProject });
      if (deleteUser) {
        const {
          data: { deleteCompanyUser: deleteCompanyUserRes },
        } = await client.mutate({
          mutation: DeleteCompanyUserDocument,
          variables: {
            id: parseInt(item?.id),
          },
          context: { clientName: ClientName.Marketplace },
        });
        console.log({ deleteCompanyUserRes });
        handleRemoveItem(index);
      } else {
        const {
          data: { updateCompanyUser: updateCompanyUserRes },
        } = await client.mutate({
          mutation: UpdateCompanyUserDocument,
          variables: {
            input: {
              user_id: `${item?.user_id}`,
              company_id: selectedCompany.id,
              access_level: selectedPerm,
            },
            id: parseInt(`${item?.id}`),
          },
          context: { clientName: ClientName.Marketplace },
        });
        console.log({ updateCompanyUserRes });
      }
    } catch (error) {
      console.log({ error });
      const err = error as Error;
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleSelectPermission = (_item) => {
    // if (selectedPermission) {
    setSelectedPermission(parseInt(_item));
    if (create) {
      onSelect(parseInt(_item));
    }
    if (update) {
      handleUpdateTeam(_item);
    }
    // }
  };

  return (
    <div className="flex w-[49%]">
      <div className="flex h-[52px] w-full items-center overflow-hidden rounded-[10px] border bg-card">
        {isLoading ? (
          <div className="flex w-full justify-between">
            <div className="text-md ml-[24px] h-[26px] w-[40%] animate-pulse rounded-[6px] pl-[20px]" />
          </div>
        ) : (
          <>
            <input
              className={cn(
                'text-md ml-[4px] h-[30px] h-[42px] w-full rounded-[6px] pl-[20px]',
                create ? 'rounded-md border' : '',
              )}
              value={item?.email ?? item?.user_id}
              onChange={(e) => {
                onChange(e);
              }}
            />
            <div className="mr-[8px] w-[170px]" key={item?.email ?? item?.user_id}>
              <SelectorDropdown
                label={''}
                name={'_item'}
                selected={selectedPermission}
                options={permissionsOptions}
                underText={''}
                isError={false}
                required={false}
                callOnBlur={({ _item }) => {
                  console.log({ _item, my_access_level }, _item);
                  handleSelectPermission(_item);
                }}
                className="m-[4px] h-[42px] w-[170px] text-base"
                disabled={!isSuperUser && my_access_level < 50}
              />
            </div>
          </>
        )}
      </div>
      <button
        className="my-auto ml-[6px]"
        onClick={() => {
          handleUpdateTeam('', true);
        }}
      >
        <Close className="text-text-color" />
      </button>
    </div>
  );
};

export default TeamCard;

type Props = {
  item?: {
    id?: string;
    email?: string;
    user_id?: string;
    permissions?: {
      access_level?: number;
    };
  };
  index?: number;
  create?: boolean;
  onChange?: (arg) => void;
  isLoading?: boolean;
  onSelect?: (arg) => void;
  handleRemoveItem?: (index) => void;
  selected?: null | number;
  update?: boolean;
};
