import React, { useRef, useState } from 'react';
import { Button, Form, Input, Label, SearchField } from 'react-aria-components';

import SearchImg from '@/assets/SearchImg.svg?react';
import { ArrowRightIcon } from '@radix-ui/react-icons';

import 'tailwindcss/tailwind.css';

const SearchInput = ({ value = '', onChange = (val) => {}, onSubmit = (val) => {} }) => {
  const handleSubmit = (e) => {
    onSubmit(e);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <Form className="relative flex w-[350px]">
      <SearchField
        className="w-full"
        name="search"
        isRequired
        onSubmit={handleSubmit}
        aria-label="Search"
      >
        <SearchImg className="absolute ml-[11px] mt-[9px]" />
        <Input
          placeholder="Search"
          className="w-full rounded-[15px] border border-input bg-card p-[6px] pl-[30px] pr-[50px] text-sm"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <button
          className="absolute right-0 my-[4px] mr-[5px] h-[25px] rounded-[13px] bg-primary px-[8px]"
          type="submit"
          onClick={handleButtonClick}
        >
          <ArrowRightIcon
            stroke="white"
            className="h-[25px] w-[20px] stroke-white font-semibold text-card"
          />
        </button>
      </SearchField>
    </Form>
  );
};

export default SearchInput;
