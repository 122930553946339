import { SecurityToken } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

const initialSecurityTokensSliceState: SecurityTokenSliceState = {
  securityToken: null,
  securityTokens: [],
  createdTokenId: null,
};

export const securityTokenSlice = lens<SecurityTokenSlice>(
  (set, get): SecurityTokenSlice => ({
    ...initialSecurityTokensSliceState,
    setSecurityToken: (securityToken_) => {
      set({
        securityToken: securityToken_,
      });
    },
    setCreatedTokenId: (createdTokenId) => {
      set({
        createdTokenId,
      });
    },
    addSecurityToken: (securityToken_) => {
      set({
        securityTokens: [securityToken_, ...get().securityTokens],
      });
    },
  }),
);

export interface SecurityTokenSliceState {
  securityToken: null | SecurityToken;
  securityTokens: any[];
  createdTokenId: null | number;
}

export interface SecurityTokenSliceActions {
  setSecurityToken?: (securityTokenArg) => void;
  addSecurityToken?: (securityTokenArg) => void;
  setCreatedTokenId: (createdTokenId) => void;
}

export type SecurityTokenSlice = SecurityTokenSliceState & SecurityTokenSliceActions;
