import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateRaiseDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

function CreateRaise({ open, setOpen = (bool) => {}, id, handleAddRaise = (input) => {} }) {
  const methods = useForm({});
  const { handleSubmit } = methods;

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleCreateRaise = async (data) => {
    try {
      setButtonLoading(true);
      const { name, amount, offering_type, security_type, closing_date } = data;
      console.log({ data, asdf: new Date(closing_date) });
      const {
        data: { createRaise: createRaiseRes },
      } = await client.mutate({
        mutation: CreateRaiseDocument,
        variables: {
          input: {
            status: 'Draft',
            name,
            amount,
            offering_type,
            security_type,
            closing_date: closing_date.toISOString(),
            company_id: parseInt(id),
          },
        },
      });
      console.log({ createRaiseRes });
      methods.reset({
        name: '',
        amount: '',
        offering_type: '',
        security_type: '',
        closing_date: '',
      });
      handleAddRaise(createRaiseRes);
      setButtonLoading(false);
      setOpen(false);
    } catch (err) {
      setButtonLoading(false);
      console.log({ err });
    }
  };

  const level3Inputs = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name',
          state: 'name',
          placeholder: 'Enter raise name',
          requiredForNextStep: true,
        },
        {
          type: 'currencyInput',
          label: 'Amount',
          state: 'amount',
          placeholder: 'Enter raise amount',
          requiredForNextStep: true,
        },
        {
          type: 'dropdown',
          label: 'Offering Type',
          state: 'offering_type',
          halfWidth: true,
          requiredForNextStep: true,
          options: [
            { label: 'Reg A', value: 'Reg A' },
            { label: 'Reg D', value: 'Reg D' },
            { label: 'Reg CF', value: 'Reg CF' },
            { label: 'Non Regulated', value: 'Non Regulated' },
          ],
        },
        {
          type: 'dropdown',
          label: 'Security Type',
          state: 'security_type',
          halfWidth: true,
          requiredForNextStep: true,
          options: [
            { label: 'Equity', value: 'Equity' },
            { label: 'Debt', value: 'Debt' },
          ],
        },
        {
          type: 'calendar',
          label: 'Closing Date',
          state: 'closing_date',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="w-[600px] max-w-[90%] rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="text-center text-[22px] font-medium">
            Create Capital Raise
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(handleCreateRaise)}>
          <WrappedInputSection methods={methods} data={level3Inputs} />
          <div className="mt-[20px] flex justify-end">
            <Button
              loading={buttonLoading}
              className="text-md w-[180px] bg-primary p-[10px]"
              // onClick={handleCreateRaise}
            >
              CREATE
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateRaise;
