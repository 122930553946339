import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AddButton } from '@/components/ui/add-button';
import { Input } from '@/components/ui/input';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  countKeysAndValues,
  countKeysAndValuesWithToggles,
  extractDataByKeyword,
  offeringAndProblemStateToExclude,
} from '@/const';
import {
  GetFormByRaiseIdDocument,
  GetRaiseByIdDocument,
  Raise,
  UpdateRaiseByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';

const nameBioPhotoInputs = (keyword) => ({
  Name: {
    type: 'input',
    label: `${keyword} member's name`,
    placeholder: `Enter ${keyword} member's name`,
  },
  Bio: {
    type: 'textarea',
    label: `${keyword} member's bio`,
    placeholder: `Enter ${keyword} member's bio`,
  },
  Photo: {
    type: 'imageUpload',
    noCrop: true,
    label: `${keyword} member's photo`,
  },
});

const nameBioPhotoOrder = ['Name', 'Bio', 'Photo'];

const questionAnswerInputs = () => ({
  Question: {
    type: 'input',
    label: 'Question',
    placeholder: 'Enter your question',
  },
  Answer: {
    type: 'textarea',
    label: 'Answer',
    placeholder: 'Enter your answer',
  },
});

const questionAnswerOrder = ['Question', 'Answer'];

const SiteBuilder = () => {
  const methods = useForm({
    defaultValues: {
      totalNumberOfFields: 38,
      totalNumberOfFieldsComplete: 0,
      offeringAgreementsImages: [],

      problemSectionToggled: true,
      solutionSectionToggled: true,
      productSectionToggled: true,
      tractionSectionToggled: true,
      businessModelSectionToggled: true,
      marketSectionToggled: true,
      competitionSectionToggled: true,
      visionModelSectionToggled: true,
      investorsSectionToggled: true,
      advisorToggled: true,
      teamToggled: true,
      FAQsSectionToggled: true,
      socialLinksSectionToggled: true,
    },
  });

  const { id } = useParams();
  const { handleSubmit, watch } = methods;
  const formValues = watch();

  const sectionClassName =
    'relative mb-[32px] grid w-full gap-[16px] rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] sm:p-[48px]';

  const innerSectionClassName =
    'bg-[#f9f9f9] p-[24px] flex flex-col gap-[24px] w-full rounded-[16px] mt-[24px]';

  const handleUpdateRaiseById = async (data) => {
    try {
      console.log({
        data,
        countKeysAndValues: countKeysAndValues(data, offeringAndProblemStateToExclude),
      });
      const {
        data: { updateRaiseById: updateRaiseByIdRes },
      } = await client.mutate({
        mutation: UpdateRaiseByIdDocument,
        variables: {
          id,
          input: {
            site_builder: { totalNumberOfFields: 38, ...data },
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ updateRaiseByIdRes });
    } catch (err) {
      console.log({ err });
    }
  };

  const handleGetFormByRaiseID = async () => {
    try {
      const {
        data: { getFormByRaiseId: getFormByRaiseIdRes },
      } = await client.query({
        query: GetFormByRaiseIdDocument,
        variables: { id, form: 'site_builder' }, // COME BACK TO THIS
      });
      // console.log({
      //   getFormByRaiseIdRes,
      //   countKeysAndValues: countKeysAndValues(
      //     getFormByRaiseIdRes,
      //     offeringAndProblemStateToExclude,
      //   ),
      // });
      methods.reset(getFormByRaiseIdRes);
      setTeamFormData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'team',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );
      setAdvisorFormData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'advisors',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );
      setFAQData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          questionAnswerInputs,
          questionAnswerOrder,
          'FAQ_',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );
      console.log({
        extractDataByKeyword: extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'advisors',
        ),
        extractDataByKeyword2: extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'team',
        ),
        extractDataByKeyword3: extractDataByKeyword(
          getFormByRaiseIdRes,
          questionAnswerInputs,
          questionAnswerOrder,
          'FAQ_',
        ),
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const formDataOfferingAgreements = [
    {
      title: '',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      inputs: [
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Header Image (Main image on offering page)',
          state: 'headerimage',
          noCrop: true,
        },
        {
          type: 'input',
          label: 'Link to Regulatory Document',
          state: 'regulatoryDocumentLink',
        },
      ],
    },
  ];

  const formDataProblemSolution = [
    {
      title: 'Problem',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'problemSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'problemHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'problemDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Solution',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'solutionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'solutionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'solutionDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Solution Image',
          state: 'solutionImageUpload',
        },
      ],
    },
    {
      title: 'Product',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'productSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'productHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'productDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Product Image',
          state: 'productImageUpload',
        },
      ],
    },
    {
      title: 'Traction',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'tractionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'tractionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'tractionDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Business Model',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'businessModelSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'businessModelHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'businessModelDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Market',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'marketSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'marketHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'marketDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Market Image',
          state: 'marketImageUpload',
        },
      ],
    },
    {
      title: 'Competition',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'competitionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'competitionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'competitionDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Vision Model',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'visionModelSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'visionModelHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'visionModelDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Investors',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'investorsSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'investorsHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: "Investor's description",
          state: 'investorsDescription',
          placeholder: "Enter investor's description",
        },
      ],
    },
  ];

  const generateFormDataByMember = useCallback(
    (title = '', typeOfMember = '', noInputs = false) => [
      {
        title,
        sectionClassName: 'w-full gap-[24px] flex flex-col',
        hasToggle: true,
        toggleState: `${typeOfMember}Toggled`,
        callOnBlur: handleUpdateRaiseById,
        inputs: noInputs
          ? []
          : [
              {
                type: 'input',
                label: 'Header',
                state: `${typeOfMember}_header`,
                placeholder: 'Enter header',
              },
              {
                type: 'textarea',
                label: `${typeOfMember}'s description`,
                state: `${typeOfMember}_description`,
                placeholder: "Enter advisor's description",
              },
            ],
      },
    ],
    [],
  );

  const additionalAdvisorInputs = (index) => [
    {
      type: 'input',
      label: "Advisor's name",
      state: `advisorsName_${index}`,
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Advisor's bio",
      state: `advisorsBio_${index}`,
      placeholder: "Enter advisor's description",
    },
    {
      type: 'imageUpload',
      noCrop: true,
      label: "Advisor's photo",
      state: `advisorsPhoto_${index}`,
    },
  ];
  const additionalTeamInputs = (index) => [
    {
      type: 'input',
      label: "Team member's name",
      state: `teamsName_${index}`,
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Team member's bio",
      state: `teamsBio_${index}`,
      placeholder: "Enter Team's description",
    },
    {
      type: 'imageUpload',
      noCrop: true,
      label: "Team member's photo",
      state: `teamsPhoto_${index}`,
    },
  ];
  const additionalFAQInputs = (index) => [
    {
      type: 'input',
      label: 'Question',
      state: `FAQ_Question_${index}`,
      placeholder: 'Enter your question',
    },
    {
      type: 'textarea',
      label: 'Answer',
      state: `FAQ_Answer_${index}`,
      placeholder: 'Enter your answer',
    },
  ];

  const [advisorFormData, setAdvisorFormData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalAdvisorInputs(0),
    },
  ]);
  const [teamFormData, setTeamFormData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalTeamInputs(0),
    },
  ]);
  const [FAQData, setFAQData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalFAQInputs(0),
    },
  ]);

  const formDataSocials = [
    {
      title: ' Social Links',
      sectionClassName,
      hasToggle: true,
      toggleState: 'socialLinksSectionToggled',
      callOnBlur: handleUpdateRaiseById,
      inputs: [
        {
          type: 'input',
          state: 'linkedInURL',
          placeholder: 'LinkedIn URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'instagramURL',
          placeholder: 'Instagram URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'facebookURL',
          placeholder: 'Facebook URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'twitterURL',
          placeholder: 'Twitter URL',
          halfWidth: true,
        },
      ],
    },
  ];

  useEffect(() => {
    handleGetFormByRaiseID();
    const scrollableElement = document.getElementById('authLayoutContainerId');
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="mx-[20px] flex flex-col items-center gap-[40px]">
      <form className="w-full max-w-[850px]" onSubmit={handleSubmit(handleUpdateRaiseById)}>
        <h2 className="mb-[48px] text-center text-4xl font-normal">
          Customize Token Offering Page
        </h2>
        <WrappedInputSection methods={methods} data={formDataOfferingAgreements} />
        <WrappedInputSection methods={methods} data={formDataProblemSolution} />
        <div className={cn(sectionClassName)}>
          <WrappedInputSection
            methods={methods}
            data={generateFormDataByMember('Investors and Advisors', 'advisor')}
          />
          <WrappedInputSection methods={methods} data={advisorFormData} />
          <AddButton
            onClick={(e) => {
              setAdvisorFormData([
                ...advisorFormData,
                {
                  sectionClassName: innerSectionClassName,
                  callOnBlur: handleUpdateRaiseById,
                  inputs: additionalAdvisorInputs(advisorFormData.length),
                },
              ]);
            }}
          />
        </div>
        <div className={cn(sectionClassName)}>
          <WrappedInputSection methods={methods} data={generateFormDataByMember('Team', 'team')} />
          <WrappedInputSection methods={methods} data={teamFormData} />
          <AddButton
            onClick={(e) => {
              setTeamFormData([
                ...teamFormData,
                {
                  sectionClassName: innerSectionClassName,
                  callOnBlur: handleUpdateRaiseById,
                  inputs: additionalTeamInputs(teamFormData.length),
                },
              ]);
            }}
          />
        </div>
        <div className={cn(sectionClassName)}>
          <WrappedInputSection
            methods={methods}
            data={generateFormDataByMember('FAQ', 'FAQ', true)}
          />
          <WrappedInputSection methods={methods} data={FAQData} />
          <AddButton
            onClick={(e) => {
              setFAQData([
                ...FAQData,
                {
                  sectionClassName: innerSectionClassName,
                  callOnBlur: handleUpdateRaiseById,
                  inputs: additionalFAQInputs(FAQData.length),
                },
              ]);
            }}
          />
        </div>
        <WrappedInputSection methods={methods} data={formDataSocials} />
      </form>
    </div>
  );
};

export default SiteBuilder;
