/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import AddBold from '@/assets/AddBold.svg?react';
import { BackNext } from '@/components/ui/back-next';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { yesOrNoDropdownInputs } from '@/const';

import Table from '../ui/table';

const CapTable = () => {
  const navigate = useNavigate();

  const [capTableLoading, setCapTableLoading] = useState(false);

  const capTableTotals = [
    { label: '# of tokens distributed', value: 0 },
    { label: 'Amout Raised', value: 0 },
    { label: 'Tokens Available', value: 0 },
  ];

  const mockCapTableData = [
    {
      name: 'Mike Berson',
      tokenQuantity: 1000,
      ownershipPercentage: '10%',
      transactionDetails: 'Completed',
    },
    {
      name: 'Jane Smith',
      tokenQuantity: 2000,
      ownershipPercentage: '20%',
      transactionDetails: 'Pending',
    },
    {
      name: 'Alice Johnson',
      tokenQuantity: 1500,
      ownershipPercentage: '15%',
      transactionDetails: 'Completed',
    },
    {
      name: 'Bob Brown',
      tokenQuantity: 500,
      ownershipPercentage: '5%',
      transactionDetails: 'Pending',
    },
    {
      name: 'Charlie Davis',
      tokenQuantity: 2500,
      ownershipPercentage: '25%',
      transactionDetails: 'Completed',
    },
    {
      name: 'Eve Walker',
      tokenQuantity: 1000,
      ownershipPercentage: '10%',
      transactionDetails: 'Completed',
    },
  ];

  const capTableOwners = useMemo(() => {
    return {
      columnNames: ['Name ', 'Token Quantity', '% Ownership', 'Transaction Details'],
      data: mockCapTableData.map((item) => [
        {
          value: item.name,
          className: 'text-md capitalize',
        },
        {
          value: item.tokenQuantity,
          className: 'text-md capitalize',
        },
        {
          value: item.ownershipPercentage,
          className: 'text-md capitalize',
        },
        {
          value: item.transactionDetails,
          className: 'text-md capitalize',
        },
      ]),
    };
  }, []);

  return (
    <div className="relative mx-[20px] flex size-full max-w-[1000px] flex-col items-center gap-[20px]">
      <h1 className="w-full text-[1.25rem] font-semibold">Cap Table</h1>

      <div className="flex w-full gap-[20px]">
        {capTableTotals.map(({ label, value }) => (
          <div className="flex w-full flex-col items-center justify-center gap-[12px] rounded-[10px] bg-card p-[25px] shadow-md">
            {/* border-2 border-black */}
            <p className="text-[22px] font-light">{value}</p>
            <p className="text-[18px] font-light">{label}</p>
          </div>
        ))}
      </div>
      <button
        className="mt-[10px] flex h-[100px] w-full items-center justify-center rounded-[6px] border bg-card p-[20px] text-[18px] font-medium text-primary shadow-md"
        onClick={() => {
          navigate('/create-cap-table');
        }}
      >
        <AddBold className="mr-3 stroke-primary" />
        Create Cap Table
      </button>
      {/* {
        <>
          <div className="mt-[30px] w-full rounded-[10px] bg-card pt-[10px] shadow-md">
            <Table
              ariaLabel="rewards-programs-table"
              columnNames={capTableOwners.columnNames}
              data={capTableOwners.data}
              isLoading={capTableLoading}
              onRowClick={(item, index) => {
                console.log({ item });
              }}
            />
          </div>
        </>
      } */}
    </div>
  );
};

export default CapTable;
