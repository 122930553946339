import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {} from 'react-router-dom';

import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  GetFormByRaiseIdDocument,
  GetRaiseByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';

const EditSecurityToken = () => {
  const methods = useForm<any>({
    defaultValues: {
      max_us_investor_percentage: [10],
    },
  });
  const { id } = useParams();
  const formValues = methods.watch();

  const [screenLoading, setScreenLoading] = useState(true);

  const createTokenInputs = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name of the Token Offering',
          state: 'name',
          requiredForNextStep: true,
          placeholder: 'Enter the name of the token offering',
        },
        {
          type: 'currencyInput',
          label: 'Amount of the Raise',
          state: 'amount',
          requiredForNextStep: true,
          placeholder: 'Enter the total amount to be raised',
        },
        {
          type: 'currencyInput',
          label: 'Minimum Investment by an Investor',
          state: 'min_investment_usd',
          requiredForNextStep: true,
          placeholder: 'Enter the minimum investment amount',
          halfWidth: true,
        },
        {
          type: 'currencyInput',
          label: 'Maximum Investment by an Investor',
          state: 'max_investment_usd',
          requiredForNextStep: true,
          placeholder: 'Enter the maximum investment amount',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of Investors Allowed Worldwide',
          state: 'max_investors_worldwide',
          requiredForNextStep: true,
          placeholder: 'Enter the maximum number of investors worldwide',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Non-accredited Investors',
          state: 'max_non_accredited_investors',
          requiredForNextStep: true,
          placeholder: 'Enter the limit for non-accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Accredited Investors',
          state: 'max_accredited_investors',
          requiredForNextStep: true,
          placeholder: 'Enter the limit for accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of US Investors',
          state: 'max_us_investors',
          requiredForNextStep: true,
          placeholder: 'Enter the limit for US investors',
          halfWidth: true,
        },
        {
          type: 'percentSlider',
          label: 'Max Percentage of US Investors',
          state: 'max_us_investor_percentage',
          requiredForNextStep: true,
          placeholder: 'Select the percentage of total holders',
        },
        {
          type: 'numberInput',
          label: 'Lockout period for US Investors (months)',
          state: 'holding_period_days_us',
          requiredForNextStep: true,
          placeholder: 'Enter the time period until security becomes tradeable',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Lockout period for Non US Investors (months)',
          state: 'holding_period_days_non_us',
          requiredForNextStep: true,
          placeholder: 'Enter the timeframe for secondary market trading',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Regulation Type',
          state: 'offering_type',
          requiredForNextStep: true,
          placeholder: 'Select the applicable regulation (e.g., Reg D, A, S, CF, Non-regulated)',
          options: [
            { label: 'Reg A', value: 'Reg A' },
            { label: 'Reg D', value: 'Reg D' },
            { label: 'Reg CF', value: 'Reg CF' },
            { label: 'Non Regulated', value: 'Non Regulated' },
          ],
          halfWidth: true,
          defaultValue: formValues?.offering_type,
        },
        {
          type: 'dropdown',
          label: 'Accreditation Process',
          state: 'accreditation_process',
          requiredForNextStep: true,
          placeholder: 'Enter the type of investor',
          options: [
            { label: 'Self Certified', value: 'Self Certified' },
            {
              label: 'Third Party Verification Required',
              value: 'Third Party Verification Required',
            },
          ],
          halfWidth: true,
          defaultValue: formValues?.accreditation_process,
        },

        {
          type: 'calendar',
          label: 'When Does the Offer Start?',
          state: 'start_date',
          requiredForNextStep: true,
          placeholder: 'Select the offer start date',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'When Does the Offer End?',
          state: 'closing_date',
          requiredForNextStep: true,
          placeholder: 'Select the offer end date',
          halfWidth: true,
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter the name of the token offering',
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Image',
          state: 'token_image',
          placeholder: 'Enter the company name',
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Icon',
          state: 'icon_url',
          placeholder: 'Enter the company name',
          requiredForNextStep: true,
        },
      ],
    },
  ];

  const handleGetRaiseById = async () => {
    try {
      const {
        data: { getRaiseById: getRaiseByIdRes },
      } = await client.query({
        query: GetRaiseByIdDocument,
        variables: { id: id },
      });
      console.log({ getRaiseByIdRes, trueFalse: !!getRaiseByIdRes?.security_token?.raise_id });
      methods.setValue('name', getRaiseByIdRes?.name);
      methods.setValue('amount', getRaiseByIdRes?.amount);
      methods.setValue('min_investment_usd', getRaiseByIdRes?.min_investment_usd);
      methods.setValue('max_investment_usd', getRaiseByIdRes?.max_investment_usd);
      methods.setValue('max_investors_worldwide', getRaiseByIdRes?.max_investors_worldwide);
      methods.setValue(
        'max_non_accredited_investors',
        getRaiseByIdRes?.max_non_accredited_investors,
      );
      methods.setValue('max_accredited_investors', getRaiseByIdRes?.max_accredited_investors);
      methods.setValue('max_us_investors', getRaiseByIdRes?.max_us_investors);
      methods.setValue('max_us_investor_percentage', getRaiseByIdRes?.max_us_investor_percentage);
      methods.setValue('holding_period_days_us', getRaiseByIdRes?.holding_period_days_us);
      methods.setValue('holding_period_days_non_us', getRaiseByIdRes?.holding_period_days_non_us);
      methods.setValue('offering_type', getRaiseByIdRes?.offering_type);
      methods.setValue('accreditation_process', getRaiseByIdRes?.accreditation_process);
      methods.setValue('start_date', new Date(getRaiseByIdRes?.start_date));
      methods.setValue('closing_date', new Date(getRaiseByIdRes?.closing_date));
      const {
        data: { getFormByRaiseId: getFormByRaiseIdRes },
      } = await client.query({
        query: GetFormByRaiseIdDocument,
        variables: { id: id, form: 'site_builder' }, // COME BACK TO THIS
      });
      console.log({ getFormByRaiseIdRes });
      methods.setValue('description', getFormByRaiseIdRes?.description);
      methods.setValue('icon_url', getFormByRaiseIdRes?.icon_url);
      methods.setValue('token_image', getFormByRaiseIdRes?.headerimage);

      setScreenLoading(false);
    } catch (err) {
      console.log({ err });
      setScreenLoading(false);
    }
  };

  useEffect(() => {
    handleGetRaiseById();
  }, []);

  return (
    <div className="relative mx-[0px] flex size-full max-w-[1200px] flex-col items-center">
      {screenLoading ? (
        <div className="flex min-h-[95px] w-full items-center justify-center">
          <div className="mt-[30%] size-[50px] animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          <h3 className="mb-[20px] text-[26px]">Edit Your Token</h3>
          <WrappedInputSection methods={methods} data={createTokenInputs} />
        </>
      )}
    </div>
  );
};

export default EditSecurityToken;
