/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import Add from '@/assets/Add.svg?react';

const CreateCapTable = () => {
  const [shareholders, setShareholders] = useState([
    { name: 'Mike Berson', shares: 60, email: 'mike@chainstarters.com' },
    { name: 'Andy Malkin', shares: 12, email: '' },
    { name: 'Jawad', shares: 3, email: '' },
  ]);
  const columns = ['Shareholder', 'Holdings', 'Email'];

  const [isPercentage, setIsPercentage] = useState(false);

  // Handles input change for each shareholder
  const handleInputChange = (index, field, value) => {
    const updatedShareholders = [...shareholders];
    updatedShareholders[index][field] = value;
    setShareholders(updatedShareholders);
  };

  // Adds a new shareholder entry
  const addShareholder = () => {
    setShareholders([...shareholders, { name: '', shares: '', email: '' }]);
  };

  // Removes a shareholder
  const removeShareholder = (index) => {
    setShareholders(shareholders.filter((_, i) => i !== index));
  };

  return (
    <div className="relative mx-[20px] flex size-full max-w-[1200px] flex-col items-center gap-[20px]">
      <div className="my-[32px] flex flex-col items-center">
        {/* Title */}
        <h1 className="text-center text-[34px] font-bold">Add your shareholders</h1>
        {/* Subtitle */}
        <p className="mt-[8px] text-center text-[16px] text-gray-600">
          Add all of your shareholders to see the power of Slate
        </p>
        {/* Draft Mode Button */}
        <button className="mt-[16px] flex items-center rounded-md border border-[#F4BB7B] bg-[#FFF2E6] p-[10px] px-[18px]">
          <span className="mr-[16px]">✏️</span>
          <span className="font-semibold">Draft Mode</span>
          <span className="ml-[8px]">(You can edit this at any time)</span>
        </button>
      </div>
      <div className="w-full rounded-md border border-black">
        <div className="flex h-[50px] border border-black bg-gray-200">
          {columns.map((item) => (
            <div className="flex w-full items-center justify-center  pl-[15px]">
              <p className="w-full font-semibold">{item}</p>
            </div>
          ))}
          <div className="min-w-[60px]" />
        </div>
        {shareholders.map((shareholder, index) => (
          <div key={index} className="flex items-center">
            <input
              type="text"
              value={shareholder.name}
              placeholder="Shareholder name"
              onChange={(e) => {
                handleInputChange(index, 'name', e.target.value);
              }}
              className="m-0 h-[65px] flex-1 border border-black p-[16px]"
            />
            <input
              type="number"
              value={shareholder.shares}
              placeholder={isPercentage ? 'Percentage' : '# of shares'}
              onChange={(e) => {
                handleInputChange(index, 'shares', e.target.value);
              }}
              className="m-0 h-[65px] flex-1 border border-black p-[16px]"
            />
            <input
              type="email"
              value={shareholder.email}
              placeholder="Shareholder email"
              onChange={(e) => {
                handleInputChange(index, 'email', e.target.value);
              }}
              className="m-0 h-[65px] flex-1 border border-black p-[16px]"
            />
            <button
              onClick={() => {
                removeShareholder(index);
              }}
              className="h-[65px] w-[60px] border border-black text-red-600"
            >
              🗑️
            </button>
          </div>
        ))}
        <button
          onClick={addShareholder}
          className="flex h-[65px] w-full items-center border border-black p-[16px] text-left text-[18px] font-medium "
        >
          <Add strokeWidth={5} className="mr-[10px] stroke-[3px]" /> Add shareholder
        </button>
        <div className="flex h-[50px] h-[65px] border border-black bg-gray-200">
          <div className="flex w-[31%] items-center justify-center pl-[15px]">
            <p className="w-full font-semibold">Total Shareholders: 3</p>
          </div>
          <div className="flex w-[31%] items-center justify-center pl-[15px]">
            <p className="w-full font-semibold">Total Stock Percentage: 75</p>
          </div>
          <div className="min-w-[60px]" />
        </div>
      </div>
    </div>
  );
};

export default CreateCapTable;
