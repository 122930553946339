import { FunctionComponent } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Controller } from 'react-hook-form';
import { ActionMeta } from 'react-select';

const Location: FunctionComponent<any> = ({ field, control, isError = false }) => {
  return (
    <Controller
      control={control}
      name={field?.state}
      rules={{ required: field?.requiredForNextStep }}
      render={({ field: _field }) => (
        <div className={`w-full`}>
          {field?.label.length > 0 && <p className="mb-1 text-sm opacity-80">{field?.label}</p>}
          <div className={`rounded-[4px] border  ${isError ? 'border-red-500' : `border-input`}`}>
            <GooglePlacesAutocomplete
              apiKey={'AIzaSyCy7e_TCzEVOTr30Z8JMIGX4PmTR3PgzCg'}
              selectProps={{
                value: _field?.value,
                defaultInputValue: _field?.value?.label || '',
                onChange: _field.onChange,
                placeholder: 'Add Location',
                styles: {
                  placeholder: (provided) => ({
                    ...provided,
                    opacity: 1,
                    color: 'gray',
                    fontFamily: 'arial',
                    fontSize: 14,
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: 'black',
                    borderColor: 'rgba(45, 52, 78, 0.1)',
                    fontFamily: 'arial',
                    fontSize: 14,
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: 'black',
                    fontFamily: 'arial',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: 'black',
                    fontFamily: 'arial',
                  }),
                  control: (provided) => ({
                    ...provided,
                    height: '48px',
                    background: 'hsl(var(--card))',
                    marginTop: '0px',
                    marginBottom: '0px',
                    cursor: 'pointer',
                    borderColor: 'transparent',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    marginLeft: '5px',
                    marginRight: '5px',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                  }),
                },
              }}
              minLengthAutocomplete={2}
              onLoadFailed={(error) => {
                console.error('Could not inject Google script', error);
              }}
            />
          </div>
        </div>
      )}
    />
  );
};

export default Location;
