import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Close from '@/assets/Close.svg?react';
import Contracts from '@/assets/Contracts.svg?react';
import Upload from '@/assets/Upload.svg?react';
import AssignFolder from '@/components/modals/assign-folder';
import { useToast } from '@/components/ui/use-toast';
import { handleUpload } from '@/lib/imageUpload';
import { CropIcon } from '@radix-ui/react-icons';
import Tippy from '@tippyjs/react';

import CropImageSelector from '../modals/crop-image-modal';

import 'tippy.js/dist/tippy.css'; // optional for styling

export function Dropzone({
  handleDrop,
  imgPreview = { hostedUrl: '' },
  text = 'Add or drop file here',
  imageWithin = false,
  label = '',
  underText = '',
  isError = '',
  smallImg = false,
  selectFromModal = true,
  filesInModal = [],
  onX = () => {},
  favicon = false,
  withoutApiCall = false,
  uploadMultiple = false,
  multipleImgPreviews = [],
  aspect = undefined,
  noCrop = false,
}: any) {
  const [loading, setLoading] = useState(false);
  const [openPicsModal, setOpenPicsModal] = useState(false);
  const [cropImage, setCropImage] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [fileState, setFileState] = useState({ file: {}, fileObject: {} });

  const { toast } = useToast();

  const getFileSize = (size) => {
    const sizeInKB = size / 1024;
    let sizeInMB;

    if (sizeInKB > 1000) {
      sizeInMB = sizeInKB / 1024;
      return `${sizeInMB.toFixed(1)} MB`;
    } else {
      return `${sizeInKB.toFixed(1)} KB`;
    }
  };

  const handleHostedUrl = (fileObject) => {
    if (favicon) {
      return '';
    } else if (withoutApiCall) {
      return fileObject;
    } else {
      return fileObject?.hostedUrl?.replace('thumbnail', 'public');
    }
  };

  const handleConfirmCrop = (file) => {
    handleDrop({
      ...file,
      // hostedUrl: `${handleHostedUrl(fileObject)}`,
      hostedUrl: file.hostedUrl,
      fullFile: file,
    });
    setLoading(false);
  };

  const onDrop = async (acceptedFiles) => {
    try {
      console.log('onDrop');

      if (acceptedFiles[0]?.type.includes('video')) {
        toast({
          title: 'Error',
          variant: 'destructive',
          description: `You can not upload videos to this file input.`,
        });
        return;
      }
      let hostedUrl;
      setLoading(true);

      if (!withoutApiCall) {
        if (!acceptedFiles[0]?.type.includes('image')) {
          toast({
            title: 'Error',
            variant: 'destructive',
            description: `Uploaded file must be an image.`,
          });
        } else if (acceptedFiles[0]?.size > 524288000) {
          toast({
            title: 'Max file size of 500mb exceeded. Please try again with a smaller image.',
            variant: 'destructive',
            description: `Error`,
          });
        }
        setLoading(true);
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        console.log({ acceptedFiles: acceptedFiles[0] });
        hostedUrl = await handleUpload(acceptedFiles[0], favicon);
      }

      const fileObject = {
        name: acceptedFiles[0]?.name,
        size: acceptedFiles[0]?.size,
        type: acceptedFiles[0]?.type,
        path: acceptedFiles[0]?.path,
        hostedUrl: hostedUrl?.url,
        id: hostedUrl?.id,
      };
      console.log({ drop: { ...fileObject } });
      if (favicon || noCrop) {
        handleDrop({
          ...fileObject,
          hostedUrl: handleHostedUrl(fileObject),
          fullFile: acceptedFiles[0],
        });
      } else {
        setCropImage(true);
        setFileState({ file: acceptedFiles[0], fileObject });
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      const err = error as Error;
      if (err?.message?.includes('Existing file found')) {
        toast({
          title: 'Error',
          variant: 'destructive',
          description: `This file has already been uploaded`,
        });
      }
      setLoading(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    console.log({ fileState, imgPreview });
  }, [fileState, imgPreview]);

  return (
    <div className="relative" key={`${imgPreview}`}>
      {label && label?.length > 0 && <p className="mb-2 text-sm opacity-80">{label}</p>}
      <div className={`flex ${smallImg ? '' : 'flex-col'}`}>
        <div
          className={`flex h-[200px] w-full cursor-pointer flex-col items-center justify-center overflow-hidden rounded-[16px] border-2 border-dashed border-input bg-card ${
            isError ? 'border-red-400' : ''
          } ${!smallImg ? '' : '!max-h-[96px] !min-h-[96px] !min-w-[96px] !max-w-[96px]'}`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {loading ? (
            <div className="size-6 animate-spin rounded-full border-t-2 border-primary" />
          ) : (
            <>
              {isDragActive ? (
                <div className="flex size-full items-center justify-center p-0">
                  <i className="fas fa-file-upload fa-2x"></i>
                  <div className="flex justify-center">
                    {imgPreview?.hostedUrl?.length > 0 ? (
                      <img src={imageWithin ? imgPreview?.hostedUrl : ''} alt="" className="" />
                    ) : (
                      <p
                        className={`text-center font-semibold text-gray-500 opacity-80 ${
                          smallImg ? 'text-sm' : ''
                        }`}
                      >
                        Drop image here ...
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <i className="fas fa-file-upload fa-2x"></i>
                  <div className="flex justify-center">
                    {imgPreview?.hostedUrl?.length > 0 && imageWithin ? (
                      <img src={imgPreview?.hostedUrl} alt="" className="" />
                    ) : (
                      <>
                        {text?.length > 0 && (
                          <p className="flex flex-col items-center text-center font-semibold opacity-80">
                            <Upload className="mb-3 fill-primary" />
                            {!smallImg && text}
                            {!!(!smallImg && selectFromModal) && (
                              <>
                                <br />
                                <p className="my-[6px] mb-[8px] text-sm">Or</p>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenPicsModal(true);
                                  }}
                                  className="rounded-[6px] bg-primary px-[11px] py-[4px] text-sm font-normal text-white"
                                >
                                  Select from My Content
                                </button>
                              </>
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="w-full">
          {/* {!!(!smallImg && selectFromModal) && (
            <div className="flex pl-[10px] pt-[10px]">
              <p className="mb-[8px] mr-[10px] mt-[4px] text-sm opacity-60">Or</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenPicsModal(true);
                }}
                className="rounded-[6px] border bg-card px-[11px] py-[6px] text-sm font-normal text-primary"
              >
                Select from My Content
              </button>
            </div>
          )} */}
          <>
            {uploadMultiple ? (
              <div className={`relative flex w-full gap-[10px] border-red-500 pl-[6px] pt-[10px]`}>
                {multipleImgPreviews.map((item, index) => (
                  <div className="relative" key={item.hostedUrl}>
                    <button
                      className="absolute right-0 top-0 mr-[-5px] mt-[-5px] flex size-[20px] items-center justify-center rounded-full border border-black bg-card shadow-md"
                      onClick={(e) => {
                        e.preventDefault();
                        onX(index);
                      }}
                    >
                      <Close className=" text-black" />
                    </button>
                    <img
                      onClick={() => {
                        window.open(item.hostedUrl, '_blank');
                      }}
                      src={item.hostedUrl}
                      className="size-[50px] cursor-pointer rounded-[5px]"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <>
                {imgPreview?.hostedUrl && (
                  <div className={`relative flex w-full border-red-500 pl-[6px] pt-[10px]`}>
                    <Tippy
                      placement="top-start"
                      content={
                        <img
                          src={`${imgPreview?.thumbnail || imgPreview?.hostedUrl}`}
                          className="max-h-[100px] max-w-[100px]"
                        />
                      }
                    >
                      <div className="line-clamp-2 flex">
                        <Contracts className="stroke-card" />
                        <a
                          href={`${imgPreview?.hostedUrl}`}
                          target="_blank"
                          rel="noreferrer"
                          data-rh="<img src='path_to_your_image.jpg' alt='Image Description' />"
                          className="ml-[10px] inline-block h-10 max-h-[40px] w-[100%] overflow-hidden text-ellipsis pr-[40px] leading-5 text-[#369EDA] underline"
                        >
                          {imgPreview?.path || `${imgPreview?.hostedUrl}`}
                          {/* {imgPreview?.path || fileState.fileObject.hostedUrl} */}
                        </a>
                      </div>
                    </Tippy>
                    {!favicon && (
                      <div className="absolute right-0 mr-[10px] flex">
                        {/* {imgPreview?.size && (
                          <p className="text-md">{getFileSize(imgPreview?.size)}</p>
                        )} */}
                        <button
                          className="p-0"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDrop({});
                            onX();
                          }}
                        >
                          <Close className="ml-[15px] text-white" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
      <p
        className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${isError ? 'text-red-500' : ''}`}
      >
        {underText}
      </p>
      {selectFromModal && (
        <AssignFolder
          setOpen={setOpenPicsModal}
          open={openPicsModal}
          folders={[]}
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
          canSelectFiles
          handleSetImage={(arg) => {
            // handleDrop(arg);
            console.log({ arg });
            if (!noCrop) {
              setCropImage(true);
            } else {
              console.log({ fileState, arg });
              handleDrop({
                ...arg,
                // hostedUrl: `${handleHostedUrl(fileObject)}`,
                hostedUrl: arg.hostedUrl,
                fullFile: arg,
              });
            }
            setFileState({
              file: arg,
              fileObject: arg,
            });
          }}
          filesInModal={filesInModal}
        />
      )}
      <CropImageSelector
        setOpen={setCropImage}
        open={cropImage}
        // imgSrc={imgPreview?.path || `${imgPreview?.hostedUrl}` || fileState.fileObject.hostedUrl}
        imgSrc={imgPreview?.path || fileState.fileObject.hostedUrl}
        handleConfirmCrop={(cropData) => {
          console.log('THE CROPPED DATA', cropData, fileState);
          handleConfirmCrop(cropData);
        }}
        fileState={fileState}
        aspect={aspect}
      />
    </div>
  );
}

export default Dropzone;

type ErrType = { message?: string };
