import DBLogo from '@/assets/DBLogo.png';
import SlateLogo from '@/assets/slateLogo.png';

const isDevProject = window._env_.IS_DEV_PROJECT;
const projectName = window._env_.PROJECT_NAME;
const env = window._env_.VITE_ENV;

const selectedProject = 'securities';

const cdnUrl = () => `https://${selectedProject}-${env}.sfo2.cdn.digitaloceanspaces.com`;

const faviconUrl = () => {
  console.log('🦛 | file: index.ts:8 | selectedProject: ', selectedProject);
  return `${cdnUrl()}/favicon/favicon.ico`;
};

const tempPasswordPrefix = 'TP!';

const baseUrl = `https://${projectName}-${env}.chainstarters.xyz`;
const apiUrl = `${baseUrl}/api`; //window._env_.API_URL; //    'https://round-five.prime-jackpot-expanse.chainstarters.io/prod';
const wsUrl = apiUrl.replace(/http(s)?/, 'ws$1');
console.debug('🦊 | apiUrl:', apiUrl);
const firebaseConfigStr = window._env_.FIREBASE_CONFIG; // '{"apiKey":"AIzaSyDa0TwpoNMG6OqQKUtnYPmkP3BWyDjr8Os","appId":"1:784709807672:web:79e506dddea867e41880cd","authDomain":"round-five-auth.prime-jackpot-expanse.chainstarters.website","messagingSenderId":"784709807672","projectId":"round-five-3200","storageBucket":"round-five-3200.appspot.com"}';
const logoUrl = window._env_.LOGO_URL;
const displayName = window._env_.DISPLAY_NAME;

const marketplaceConfigs = {
  'dashboard-securities': {
    marketplaceUrl: 'https://slate.chainstarters.xyz',
    marketplaceGraphqlUrl: 'https://securities-dev.chainstarters.xyz/api',
    marketplacePojectName: 'securities',
    theme: {},
    projectName: 'Slate',
    projectLogo: SlateLogo,
  },
  'dashboard-distributed-brokers': {
    marketplaceUrl: 'https://distributedbrokers.com',
    marketplaceGraphqlUrl: 'https://distributed-brokers-dev.chainstarters.xyz/api',
    marketplacePojectName: 'distributed-brokers',
    theme: {},
    projectName: 'Distributed Brokers',
    projectLogo: DBLogo,
  },
};
const config = {
  cdnUrl,
  faviconUrl,
  apiUrl,
  wsUrl,
  isDevProject,
  firebaseConfig: JSON.parse(firebaseConfigStr),
  sendSignupEmailVerificationAlways: false, // if true, on signup even if the user.emailVerified is true, the email will still be sent
  waitForEmailVerified: true,
  showTokenExpiresIn: false,
  projectQueryParamsKey: 'project',
  companyQueryParamsKey: 'company',
  dashboard_graphql_endpoint_url: apiUrl,
  isMetaworks: projectName === 'dashboard-mw',
  tempPasswordPrefix,
  projectEnv: env,
  tokenRefreshThreshold: 0.5, // 1 minute,
  logoUrl,
  displayName,
  selectedProjectName: marketplaceConfigs[projectName].marketplacePojectName,
  graphql_endpoint_url: marketplaceConfigs[projectName].marketplaceGraphqlUrl, // 'https://round-six-dev.chainstarters.xyz/api', //
  projectNameToDisplay: marketplaceConfigs[projectName].projectName,
  projectLogoToDisplay: marketplaceConfigs[projectName].projectLogo,
};
console.log({ marketplaceConfigs, projectName, env, config });

export default config;
