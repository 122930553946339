import { Kind, OperationDefinitionNode, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';

import config from '@/config';
import { ApolloClient, ApolloLink, from, HttpLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { getRawFireTokenExpiry, isRawFireTokenExpired, logout } from './helpers/auth';
import { getLocalStorageToken, getNewRawFireToken } from './utils/auth';
import { auth } from './firebase';

export enum ClientName {
  Dashboard = 'dashboard',
  Marketplace = 'marketplace',
}

let hasMarketplaceUrl = false;
export let client;

export const getApolloClient = () => {
  const uri = config.graphql_endpoint_url;
  console.debug('🦊 | getApolloClient', uri);
  if (client && hasMarketplaceUrl === Boolean(uri)) return client;
  console.debug('🦊 | getApolloClient | making a new one');
  const authMiddleware = new (ApolloLink as any)(async (operation, forward) => {
    const context = operation.getContext() as { [key: string]: any; clientName: ClientName };
    let token;
    if (context.useFirebaseToken) {
      // TODO: consider using similar way to useRenewAdminTokenSetup to check firebase token expiry
      let rawFireAccessToken =
        context.rawFireAccessToken ?? (auth?.currentUser as any)?.accessToken;
      if (!auth?.currentUser) {
        console.warn('🦊 | getApolloClient | useFirebaseToken | auth.currentUser is null', {
          'context.rawFireAccessToken': context.rawFireAccessToken,
        });
      }
      if (!rawFireAccessToken) {
        console.debug('no rawFireAccessToken. auth?.currentUser', auth?.currentUser); // TODO: fix it by waiting for AuthStateChanged to be ready first
        logout();
        // vvvv comment this out for now to preserve the logs for troubleshooting. if experience getting stuck at /, let me know
        // window.location.reload();
        return;
      }
      const skipTokenExpiredCheck = Boolean(context.rawFireAccessToken); // if from signin will get token passed through context and don't have to check expiry
      if (!skipTokenExpiredCheck) {
        const isExpired = isRawFireTokenExpired();
        const expirationTime = getRawFireTokenExpiry();
        console.debug(
          '🦊 | authMiddleware | isRawFireTokenExpired | isExpired:',
          isExpired,
          expirationTime,
        );
        if (isExpired) {
          rawFireAccessToken = await getNewRawFireToken();
          console.debug(
            '🦊 | authMiddleware | isRawFireTokenExpired | rawFireAccessToken:',
            rawFireAccessToken,
            'auth?.currentUser',
            auth?.currentUser,
          );
        }
      }
      // TODO: do we have to re signin (in the code) to upadte auth.currentUser?
      const firebaseToken = `fire:${rawFireAccessToken}`;
      token = firebaseToken;
    } else if (context.clientName === ClientName.Dashboard) {
      token = localStorage.getItem('adminToken');
      // NOTE: not checking token expiration here because it should already have been reset in the useRenewAdminTokenSetup or reviveAuth
    } else if (context.clientName === ClientName.Marketplace || !context.clientName) {
      if (!uri) {
        console.debug('no margetplace uri found in localstorage');
        return;
      }
      // not sure if we have to use different token for marketplace but we are using the same token as the dashboard for now
      token = localStorage.getItem('adminToken');
    }
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          Authorization: token,
        },
      };
    });
    return forward(operation);
  });
  const logLink = new ApolloLink((operation, forward) => {
    console.time(operation.operationName);
    return forward(operation).map((result) => {
      console.group(`====> GraphQL Request: ${operation.operationName}`);
      console.timeEnd(operation.operationName);
      console.debug(`${operation.operationName} response: `, result.data);
      console.groupEnd();
      return result;
    });
  });
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors != null) {
      for (const { message, path } of graphQLErrors) {
        console.log(`[GraphQL error]: Message: ${message} Path: ${path}`);
        if (message.toLowerCase().includes('invalid authorization token')) {
          /// vvv temporary skip auto renew token because it might be broken
          // logout();
          console.error(
            '🤯 this should not happen because the token should have already been renewed',
          );
        }
      }
    }
    if (networkError != null) console.log(`[Network error]: ${networkError}`);
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => {
        console.log('🦛 | file: apollo.ts:121 | _operation:', JSON.stringify(_operation, null, 2));
        const operationDefinition = _operation.query.definitions.find(
          (d) => d.kind === Kind.OPERATION_DEFINITION,
        ) as OperationDefinitionNode;

        const isMutation = operationDefinition?.operation === OperationTypeNode.MUTATION;
        console.log('🦛 | file: apollo.ts:122 | isMutation:', isMutation);
        return !!error && !isMutation;
      },
    },
  });

  const dashboardLink = new HttpLink({ uri: config.apiUrl });
  const marketplaceLink = new HttpLink({
    uri,
  });

  const createMarketplaceWsClient = () => {
    const wsUri = uri?.replace(/http(s)?/, 'ws$1');
    console.debug('🦊 | getApolloClient | createMarketplaceWsClient', { uri, wsUri });
    return createClient({
      url: wsUri,
      // shouldRetry: () => true,
      connectionParams: () => {
        const token = getLocalStorageToken();
        console.debug('🦊 | getApolloClient | token:', token);
        return {
          headers: {
            Authorization: token,
          },
        };
      },
      on: {
        opened: (socket) => {
          console.debug('🎧 subscription web socket connection | opened');
        },
        connecting: () => {
          console.debug('🎧 subscription web socket connection | connecting');
        },
        connected: (socket, payload) => {
          console.debug('🎧 subscription web socket connection | connected');
        },
        error: (err) => {
          console.error('🎧 subscription web socket connection | error |', err);
        },
      },
    });
  };

  const wsClient = createMarketplaceWsClient();

  const wssMarketplaceLink = new GraphQLWsLink(wsClient);
  const splitMarketplaceLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      const isWss =
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION;
      return isWss;
    },
    wssMarketplaceLink,
    marketplaceLink,
  );

  const splitDashboardMarketplaceLink = split(
    (operation) => {
      return operation.getContext().clientName === 'dashboard';
    },
    dashboardLink,
    splitMarketplaceLink,
  );

  client = new ApolloClient({
    link: from([
      authMiddleware,
      // authResponseCheck,
      logLink,
      retryLink,
      errorLink,
      splitDashboardMarketplaceLink,
      splitMarketplaceLink,
    ]),
    defaultOptions: {
      query: { errorPolicy: 'all', fetchPolicy: 'network-only' },
    },
    cache: new InMemoryCache(),
    name: 'datacorp-dashboard', // TODO: recheck the name and version
    version: '0.1.0',
  });
  hasMarketplaceUrl = Boolean(uri);
  return client;
};
