import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import AddBold from '@/assets/AddBold.svg?react';
import ChartLine from '@/assets/ChartLine.svg?react';
import OnboardingModal from '@/components/modals/onboard-modal';
import CompanyCard from '@/components/ui/company-card';
import {
  Company,
  CreateSecurityTokenDocument,
  GetUserCompaniesDocument,
  ListRaisesByCompanyIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import SectionDivider from '../ui/section-divider';

function BusinessHome() {
  const navigate = useNavigate();
  // const { addSecurityToken, securityTokens } = useBoundStore((state) => state.securityTokenSlice);

  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState<Company[] | []>([]);
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [raises, setRaises] = useState([]);
  const [raisesLoading, setRaisesLoading] = useState(true);
  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);

  const raisesWithLoad = useMemo(() => {
    if (raisesLoading) {
      return [1];
    } else {
      return raises;
    }
  }, [raises, raisesLoading]);

  const handleListRaises = async () => {
    try {
      const {
        data: { listRaisesByCompanyId: listRaisesByCompanyIdRes },
      } = await client.query({
        query: ListRaisesByCompanyIdDocument,
        variables: { company_id: selectedCompany?.id },
      });
      console.log({ listRaisesByCompanyIdRes });
      setRaises(listRaisesByCompanyIdRes);
      setRaisesLoading(false);
    } catch (err) {
      setRaisesLoading(false);
      console.log({ err });
    }
  };

  useEffect(() => {
    handleListRaises();
  }, [selectedCompany]);

  return (
    <div className="grid w-full max-w-[1320px] flex-col gap-[25px]">
      <SectionDivider
        titles={[`Token Offering`]}
        color="#FFBD39"
        img={<ChartLine className="size-[22px] stroke-white" />}
      />
      <div className="flex w-full flex-wrap gap-[20px] p-[5px]">
        <button
          className="box-border flex h-[205px] w-[420px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
          onClick={() => {
            navigate('/security-token-view');
            // handleCreateSecurityToken();
          }}
        >
          <p className="ml-[-15px] flex font-semibold text-primary">
            <AddBold className="mr-3 stroke-primary" />
            Create Security Token
          </p>
        </button>
        {raisesWithLoad?.map((item, index) => (
          <CompanyCard loading={raisesLoading} item={item} index={index} />
        ))}
      </div>
      <OnboardingModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default BusinessHome;
