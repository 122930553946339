import React, { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';
import * as ProgressPrimitive from '@radix-ui/react-progress';

function ProgressLevelsBar({ steps = [], currentLevel = 0 }) {
  const calculateLevel = (levelForComplete = 0) => {
    if (levelForComplete === currentLevel) {
      return {
        status: 'In Progress',
        textClass: 'text-blue-500',
        circleClass: ` flex items-center justify-center border-[3px] border-primary`,
        renderInner: () => <div className="size-[22px] rounded-full bg-primary"></div>,
      };
    } else if (levelForComplete > currentLevel) {
      return {
        status: 'Pending',
        textClass: 'text-gray-400',
        circleClass: 'border-[3px] border-primary flex items-center justify-center',
        renderInner: () => <></>,
      };
    } else {
      return {
        status: 'Completed',
        textClass: 'text-green-500',
        circleClass: 'border-[3px] border-primary',
        renderInner: () => (
          <svg
            className="size-[24px] stroke-primary"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
          </svg>
        ),
      };
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between px-[50px]">
        {steps.map((step, index) => (
          <div
            key={index}
            className={cn(
              'flex w-full flex-col items-center',
              index == steps.length - 1 && 'max-w-[45px]',
            )}
          >
            <div className="flex w-full items-center">
              <div
                className={cn(
                  ' flex h-[34px] w-[34px] min-w-[34px] items-center justify-center rounded-full',
                  calculateLevel(index)?.circleClass,
                )}
              >
                {calculateLevel(index)?.renderInner()}
              </div>
              {index !== steps.length - 1 && (
                <div className="mx-[20px] h-[4px] w-full bg-gray-300">
                  <div
                    className={cn(
                      'transition-all duration-500',
                      index < currentLevel ? 'w-full bg-blue-500' : 'w-0 bg-primary',
                    )}
                    style={{ height: '4px' }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-between">
        {steps.map((step, index) => (
          <div key={step} className="flex w-[150px] flex-col items-center">
            <p className="mt-[16px] text-lg font-semibold">{step}</p>
            <p className={cn(calculateLevel(index)?.textClass)}>{calculateLevel(index)?.status}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProgressLevelsBar;
