import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { isAddress } from 'ethereum-address';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { BackNext } from '@/components/ui/back-next';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import config from '@/config';
import { chainIdToName } from '@/const';
import { CreateSecurityTokenDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore, { StoreType } from '@/store';
import { availableMetakeeps, primaryChainId } from '@/store/project-slice';

const CreateToken: FunctionComponent = () => {
  const _availableMetakeeps = useBoundStore((state: StoreType) => availableMetakeeps(state));

  const [defaultValues, setDefaultValues] = useState<any>({
    max_percentage_of_us_investors: [0],
  });
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const methods = useForm({
    defaultValues,
  });
  const formValues = methods.watch();

  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const setSecurityToken = useBoundStore((state) => state.securityTokenSlice.setSecurityToken);

  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const { toast } = useToast();

  const stripNonNumberCharacters = (input: string | number): string => {
    const numberString = typeof input === 'string' ? input.replace(/\D/g, '') : input.toString();
    return numberString;
  };

  const tokenDetails = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name of the Token Offering',
          state: 'token_offering_name',
          placeholder: 'Enter the name of the token offering',
        },
        {
          type: 'currencyInput',
          label: 'Amount of the Raise',
          state: 'amount_of_raise',
          placeholder: 'Enter the total amount to be raised',
        },
        {
          type: 'currencyInput',
          label: 'Minimum Investment by an Investor',
          state: 'minimum_investment',
          placeholder: 'Enter the minimum investment amount',
          halfWidth: true,
        },
        {
          type: 'currencyInput',
          label: 'Maximum Investment by an Investor',
          state: 'maximum_investment',
          placeholder: 'Enter the maximum investment amount',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of Investors Allowed Worldwide',
          state: 'max_investors_worldwide',
          placeholder: 'Enter the maximum number of investors worldwide',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Non-accredited Investors',
          state: 'max_non_accredited_investors',
          placeholder: 'Enter the limit for non-accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Accredited Investors',
          state: 'max_accredited_investors',
          placeholder: 'Enter the limit for accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of US Investors',
          state: 'max_us_investors',
          placeholder: 'Enter the limit for US investors',
          halfWidth: true,
        },
        {
          type: 'percentSlider',
          label: 'Max Percentage of US Investors',
          state: 'max_percentage_of_us_investors',
          placeholder: 'Select the percentage of total holders',
        },
        {
          type: 'calendar',
          label: 'Lockout period for US Investors (months)',
          state: 'security_tradeable_timeframe',
          placeholder: 'Enter the time period until security becomes tradeable',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'Lockout period for Non US Investors (months)',
          state: 'secondary_market_tradeable_timeframe',
          placeholder: 'Enter the timeframe for secondary market trading',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Regulation Type',
          state: 'regulation_type',
          placeholder: 'Select the applicable regulation (e.g., Reg D, A, S, CF, Non-regulated)',
          options: [
            { label: 'Accrued', value: 'Accrued' },
            { label: 'Monthly', value: 'Monthly' },
            { label: 'Quarterly', value: 'Quarterly' },
            { label: 'Annually', value: 'Annually' },
          ], // Assuming options array exists
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Accreditation Process',
          state: 'investor_type',
          placeholder: 'Enter the type of investor',
          options: [
            { label: 'Self Certified', value: 'Self Certified' },
            {
              label: 'Third Party Verification Required',
              value: 'Third Party Verification Required',
            },
          ], // Assuming options array exists
          halfWidth: true,
        },

        {
          type: 'calendar',
          label: 'When Does the Offer Start?',
          state: 'offer_start_date',
          placeholder: 'Select the offer start date',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'When Does the Offer End?',
          state: 'offer_end_date',
          placeholder: 'Select the offer end date',
          halfWidth: true,
        },
      ],
    },
  ];

  const handleCreateToken = async (data) => {
    try {
      console.log('FIRED 1');

      console.log('FIRED 3');
      setCreateLoading(true);
      const {
        max_investment,
        initial_price,
        total_supply,
        icon_url,
        description,
        symbol,
        name,
        allocations,
        chain_id,
      } = data;

      const {
        data: { createSecurityToken: createSecurityTokenRes },
      } = await client.mutate({
        mutation: CreateSecurityTokenDocument,
        variables: {
          input: {
            name,
            symbol,
            // raise_id: parseInt(`${raise_id}`),
            total_supply: stripNonNumberCharacters(total_supply),
            // decimals
            max_investment: stripNonNumberCharacters(max_investment),
            chain_id: parseInt(chain_id),
            description,
            icon_url: icon_url.hostedUrl,
            initial_price: stripNonNumberCharacters(initial_price),
            allocations:
              allocations[0].user_id === '' || allocations[0].quantity === ''
                ? []
                : allocations
                    .filter(({ quantity, user_id }) => user_id && quantity)
                    .map(({ quantity, user_id }) => ({ quantity, user_id })),
          },
        },
      });
      console.log({ createSecurityTokenRes });
      setCreateLoading(false);
      // setTimeout(() => {
      //   setSecurityToken({
      //     name,
      //     description,
      //     icon_url: icon_url.hostedUrl,
      //     symbol: symbol,
      //     raise_id,
      //   });
      // }, 1200);
      // navigate(`/fundraise/${company_id}?project=round-six&raise=${raise_id}`);
    } catch (err) {
      setCreateLoading(false);
      console.log({ err });
    }
  };

  const [quantityErr, setQuantityErr] = useState('');
  const [validWalletAddresses, setValidWalletAddresses] = useState<(boolean | string)[]>([]);

  const isEmailOrAddress = (input) => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    const isAddressInput = isAddress(input);

    return { isEmail, isAddress: isAddressInput };
  };

  const validateInputs = useCallback((data) => {}, []);

  useEffect(() => {
    console.log({ formValues, validWalletAddresses });
  }, [formValues, validWalletAddresses]);

  return (
    <form
      className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
      onSubmit={handleSubmit(handleCreateToken)}
    >
      <h1 className="text-3xl font-semibold"> Token Details </h1>
      {pageLoading ? (
        <div className="flex h-full items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          <WrappedInputSection methods={methods} data={tokenDetails} />
        </>
      )}
      <div className="mb-[30px] mt-auto">
        <BackNext
          numOfLevels={3}
          finalBtnText={`CREATE TOKEN`}
          currentLevel={1}
          setCurrentLevel={() => {}}
          loading={createLoading}
        />
      </div>
    </form>
  );
};

export default CreateToken;
