import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import Confetti from '@/assets/Confetti2.png';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';

type CongratsModalProps = {
  open: boolean;
  setOpen: (bool) => void;
  onConfirm: () => void;
  title?: string;
  subTitle?: string;
  nameToType?: string;
};

function CongratsModal({
  open,
  setOpen = (bool) => {},
  title = '',
  subTitle = '',
  nameToType = '',
  onConfirm = () => {},
}: CongratsModalProps) {
  const navigate = useNavigate();

  const [confirmText, setConfirmText] = useState('');

  const handleNext = async () => {
    onConfirm();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        setOpen(!open);
      }}
    >
      <DialogContent
        className="flex w-[450px] max-w-[90%] flex-col overflow-hidden rounded-[16px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img className="absolute left-0 top-0 mt-[-100px] opacity-50" src={Confetti} />
        {title && <h1 className="mt-[10px] text-center text-3xl font-medium">Congratulations!</h1>}
        {subTitle && <p className="text-center text-lg ">{subTitle}</p>}

        <Button
          className="z-40 w-full rounded-md border bg-primary py-[22px] text-lg text-card"
          onClick={() => {
            navigate('/company-home');
            setOpen(false);
          }}
        >
          View My Token
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default CongratsModal;
