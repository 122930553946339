import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { CreateCompanyDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { handleSelectProject, logout, reviveAuth } from '@/lib/helpers/auth';
import useBoundStore from '@/store';

import { statesInAmerica } from '../../const';

export function OnboardingModal({ open = false, setOpen = (boolean) => {} }) {
  const { addCompany } = useBoundStore((state) => state.companySlice);
  const methods = useForm({ defaultValues: {} });
  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const [onboardLevel, setOnboardLevel] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);

  const handleCancel = () => {
    if (onboardLevel >= 1) {
      setOnboardLevel(0);
    } else {
      // setOpen(false);
    }
  };

  const handleCreateCompany = async (data) => {
    try {
      if (onboardLevel < 2) {
        setOnboardLevel(onboardLevel + 1);
      } else {
        setCreateLoading(true);
        console.log({ data });
        const {
          website,
          tax_id,
          state_of_organization,
          companyPhone,
          logo,
          address,
          date_of_organization,
          legal_name,
          legal_status,
          CEOphone,
          CEOname,
          CEOemail,
        } = data;
        const {
          data: { createCompany: createCompanyRes },
        } = await client.mutate({
          mutation: CreateCompanyDocument,
          variables: {
            input: {
              website,
              tax_id,
              state_of_organization,
              phone: companyPhone,
              logo: logo.hostedUrl,
              address: address?.label || '',
              date_of_organization: date_of_organization
                ? new Date(date_of_organization)?.toISOString()
                : new Date()?.toISOString(),
              legal_name,
              legal_status,
              ceo: {
                phone: CEOphone,
                name: CEOname,
                email: CEOemail,
              },
            },
          },
        });
        console.log({ createCompanyRes });
        setCreateLoading(false);
        addCompany(createCompanyRes);
        setOpen(false);
        // navigate('/company-home');
      }
    } catch (err) {
      setCreateLoading(false);
      console.log({ err });
    }
  };

  const titles = ['Create Your Company', 'Company Information', 'Company Information'];

  const level1Details = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Company Legal Name',
          state: 'legal_name',
          placeholder: 'Enter the legal name of your company',
          requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          label: 'Type of Organization',
          state: 'legal_status',
          placeholder: 'Select your website theme',
          options: [
            { label: 'Corporation', value: 'Corporation' },
            { label: 'S Corporation', value: 'S Corporation' },
            { label: 'LLC', value: 'LLC' },
            { label: 'Public Benefit Corporation', value: 'Public Benefit Corporation' },
            { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
            { label: 'Partnership', value: 'Partnership' },
            { label: '501(c)(3) Non-Profit', value: '501(c)(3) Non-Profit' },
          ],
          // requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Company Logo',
          state: 'logo',
          smallImg: true,
          noCrop: true,
          selectFromModal: false,
          placeholder: 'Upload your company logo',
          // requiredForNextStep: true,
        },
      ],
    },
  ];
  const level2Details = [
    {
      inputs: [
        {
          type: 'dropdown',
          label: 'State of Incorporation',
          state: 'state_of_organization',
          placeholder: 'Select the state of incorporation',
          options: statesInAmerica,
        },
        {
          type: 'calendar',
          label: 'Date of Incorporation',
          state: 'date_of_organization',
          placeholder: 'MM/DD/YYYY',
        },
        {
          type: 'input',
          label: 'Tax ID',
          state: 'tax_id',
          placeholder: 'Enter your tax identification number',
        },
      ],
    },
  ];
  const level3Details = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Company Website',
          state: 'website',
          placeholder: 'Enter your company’s website URL',
        },
        {
          type: 'phone',
          label: 'Company Phone Number',
          state: 'compnayPhone',
          placeholder: 'Enter the main phone number for your company',
        },
        {
          type: 'location',
          label: 'Company Address',
          state: 'address',
          placeholder: 'Enter the primary address of your company',
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Name',
          state: 'CEOname',
          placeholder: 'Enter the full name of the CEO or managing partner',
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Email',
          state: 'CEOemail',
          placeholder: 'Enter the email address of the CEO or managing partner',
        },
        {
          type: 'phone',
          label: 'CEO/Managing Partner’s Phone Number',
          state: 'CEOphone',
          placeholder: 'Enter the phone number of the CEO or managing partner',
        },
      ],
    },
  ];

  const handleBackLogout = () => {
    if (onboardLevel === 0) {
      logout(navigate);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open_) => {
        if (!open_) handleCancel();
      }}
    >
      <DialogContent
        className="flex h-[760px] max-h-[90%] flex-col rounded-[16px] sm:max-w-[800px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogHeader>
          <DialogTitle className="mb-[12px] text-center text-lg">
            {titles[onboardLevel]}
          </DialogTitle>
        </DialogHeader>
        <form
          className="flex h-full min-h-[640px] grow flex-col"
          onSubmit={handleSubmit(handleCreateCompany)}
        >
          {onboardLevel == 0 && <WrappedInputSection methods={methods} data={level1Details} />}
          {onboardLevel == 1 && <WrappedInputSection methods={methods} data={level2Details} />}
          {onboardLevel == 2 && <WrappedInputSection methods={methods} data={level3Details} />}
          <div className="mt-auto flex">
            <BackNext
              numOfLevels={3}
              finalBtnText={`CREATE COMPANY`}
              currentLevel={onboardLevel}
              setCurrentLevel={setOnboardLevel}
              loading={createLoading}
              onBack={handleBackLogout}
              // showBackAfter={1}
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default OnboardingModal;
