import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import Team from '@/assets/Team.svg?react';
import { BackNext } from '@/components/ui/back-next';
import { Button } from '@/components/ui/button';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import {
  GetCompanyByIdDocument,
  UpdateCompanyDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import { statesInAmerica } from '../../const';
import SectionDivider from '../ui/section-divider';

function CompanyDetails() {
  const methods = useForm({ defaultValues: {} });
  const formValues = methods.watch();
  const { handleSubmit } = methods;

  const [companyLoading, setCompanyLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);

  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);

  const handleGetCompanyDetails = async () => {
    try {
      const {
        data: { getCompanyById: getCompanyByIdRes },
      } = await client.query({
        query: GetCompanyByIdDocument,
        variables: { getCompanyByIdId: parseInt(`${selectedCompany.id}`) },
      });
      setCompanyLoading(false);
      console.log({ getCompanyByIdRes });
      methods.setValue('legal_name', getCompanyByIdRes.legal_name);
      methods.setValue('legal_status', getCompanyByIdRes.legal_status);
      methods.setValue('logo', getCompanyByIdRes.logo);
      methods.setValue('state_of_organization', getCompanyByIdRes.state_of_organization);
      methods.setValue('date_of_organization', new Date(getCompanyByIdRes.date_of_organization));
      methods.setValue('tax_id', getCompanyByIdRes.tax_id);
      methods.setValue('website', getCompanyByIdRes.website);
      methods.setValue('phone', getCompanyByIdRes.phone);
      methods.setValue('address', { label: getCompanyByIdRes.address });
      methods.setValue('CEOname', getCompanyByIdRes.officers[0].full_legal_name);
      methods.setValue('CEOemail', getCompanyByIdRes.officers[0].email);
      methods.setValue('CEOphone', getCompanyByIdRes.officers[0].cell_phone);

      console.log({ getCompanyByIdRes });
    } catch (err) {
      setCompanyLoading(false);
      console.log({ err });
    }
  };

  const handleUpdateCompany = async (data) => {
    try {
      setCreateLoading(true);
      console.log({ data });
      const {
        website,
        tax_id,
        state_of_organization,
        phone,
        logo,
        address,
        date_of_organization,
        legal_name,
        legal_status,
        CEOphone,
        CEOname,
        CEOemail,
      } = data;
      const {
        data: { createCompany: createCompanyRes },
      } = await client.mutate({
        mutation: UpdateCompanyDocument,
        variables: {
          id: selectedCompany?.id,
          input: {
            website,
            tax_id,
            state_of_organization,
            phone: phone,
            logo: logo.hostedUrl || logo,
            address: address.label,
            date_of_organization: new Date(parseInt(date_of_organization)).toISOString(),
            legal_name,
            legal_status,
            ceo: {
              phone: CEOphone,
              name: CEOname,
              email: CEOemail,
            },
          },
        },
      });
      console.log({ createCompanyRes });
      setCreateLoading(false);
      // addCompany(createCompanyRes);
      // setOpen(false);
    } catch (err) {
      setCreateLoading(false);
      console.log({ err });
    }
  };

  useEffect(() => {
    handleGetCompanyDetails();
  }, [selectedCompany]);

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  const companyDetails = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Company Legal Name',
          state: 'legal_name',
          placeholder: 'Enter the legal name of your company',
          requiredForNextStep: true,
        },
        {
          type: 'checkboxColumn',
          label: 'Type of Organization',
          state: 'legal_status',
          placeholder: 'Select your website theme',
          options: [
            { label: 'Corporation', value: 'Corporation' },
            { label: 'S Corporation', value: 'S Corporation' },
            { label: 'LLC', value: 'LLC' },
            { label: 'Public Benefit Corporation', value: 'Public Benefit Corporation' },
            { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
            { label: 'Partnership', value: 'Partnership' },
            { label: '501(c)(3) Non-Profit', value: '501(c)(3) Non-Profit' },
          ],
          requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Company Logo',
          state: 'logo',
          smallImg: true,
          noCrop: true,
          placeholder: 'Upload your company logo',
          requiredForNextStep: true,
        },
        {
          type: 'dropdown',
          label: 'State of Incorporation',
          state: 'state_of_organization',
          placeholder: 'Select the state of incorporation',
          defaultValue: formValues?.state_of_organization,
          options: statesInAmerica,

          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'Date of Incorporation',
          state: 'date_of_organization',
          placeholder: 'MM/DD/YYYY',

          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Tax ID',
          state: 'tax_id',
          placeholder: 'Enter your tax identification number',
        },

        {
          type: 'input',
          label: 'Company Website',
          state: 'website',
          placeholder: 'Enter your company’s website URL',

          halfWidth: true,
        },
        {
          type: 'phone',
          label: 'Company Phone Number',
          state: 'phone',
          placeholder: 'Enter the main phone number for your company',

          halfWidth: true,
        },
        {
          type: 'location',
          label: 'Company Address',
          state: 'address',
          placeholder: 'Enter the primary address of your company',

          halfWidth: true,
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Name',
          state: 'CEOname',
          placeholder: 'Enter the full name of the CEO or managing partner',
        },
        {
          type: 'input',
          label: 'CEO/Managing Partner’s Email',
          state: 'CEOemail',
          placeholder: 'Enter the email address of the CEO or managing partner',
        },
        {
          type: 'phone',
          label: 'CEO/Managing Partner’s Phone Number',
          state: 'CEOphone',
          placeholder: 'Enter the phone number of the CEO or managing partner',
        },
      ],
    },
  ];

  return (
    <form className="max-w-[1100px]" onSubmit={handleSubmit(handleUpdateCompany)}>
      {companyLoading ? (
        <div className="flex min-h-[95px] w-full items-center justify-center">
          <div className="mt-[25vh] size-[50px] animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          <div className="mb-[20px]">
            <SectionDivider
              titles={[`Edit Company Details`]}
              img={<Team stroke="white" />}
              color="#ff6661"
            />
          </div>
          <WrappedInputSection methods={methods} data={companyDetails} />
          <div className="mt-[30px]">
            <BackNext
              className="mt-[30px]"
              numOfLevels={1}
              finalBtnText={'Update'}
              loading={createLoading}
            />
          </div>
        </>
      )}
    </form>
  );
}

export default CompanyDetails;
