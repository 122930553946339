/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import Contract from '@/assets/Contracts.svg?react';
import { AddButton } from '@/components/ui/add-button';
import { BackNext } from '@/components/ui/back-next';
import ProgressLevelsBar from '@/components/ui/progress-levels-bar';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
// import { daysUntilDate } from '@/const';
import {
  countKeysAndValues,
  countKeysAndValuesWithToggles,
  daysUntilDate,
  extractDataByKeyword,
  offeringAndProblemStateToExclude,
} from '@/const';
import {
  CreateRaiseDocument,
  CreateSecurityTokenDocument,
  GetFormByRaiseIdDocument,
  GetRaiseByIdDocument,
  UpdateRaiseByIdDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { Pencil2Icon } from '@radix-ui/react-icons';

import CongratsModal from '../modals/congrats-modal';

const nameBioPhotoInputs = (keyword) => ({
  Name: {
    type: 'input',
    label: `${keyword} member's name`,
    placeholder: `Enter ${keyword} member's name`,
  },
  Bio: {
    type: 'textarea',
    label: `${keyword} member's bio`,
    placeholder: `Enter ${keyword} member's bio`,
  },
  Photo: {
    type: 'imageUpload',
    noCrop: true,
    label: `${keyword} member's photo`,
  },
});

const nameBioPhotoOrder = ['Name', 'Bio', 'Photo'];

const questionAnswerOrder = ['Question', 'Answer'];

const questionAnswerInputs = () => ({
  Question: {
    type: 'input',
    label: 'Question',
    placeholder: 'Enter your question',
  },
  Answer: {
    type: 'textarea',
    label: 'Answer',
    placeholder: 'Enter your answer',
  },
});

function SecurityTokenView() {
  const methods = useForm<any>({
    defaultValues: {
      max_us_investor_percentage: [0],
      // NEW STUFF
      offeringAgreementsImages: [],
      problemSectionToggled: false,
      solutionSectionToggled: false,
      productSectionToggled: false,
      tractionSectionToggled: false,
      businessModelSectionToggled: false,
      marketSectionToggled: false,
      competitionSectionToggled: false,
      visionModelSectionToggled: false,
      investorsSectionToggled: false,
      advisorToggled: false,
      teamToggled: false,
      FAQsSectionToggled: false,
      socialLinksSectionToggled: false,
    },
  });
  const [currentLevel, setCurrentLevel] = useState(0);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(true);
  const [getRaiseByIdCompleted, setGetRaiseByIdCompleted] = useState(false);
  const [securityTokenId, setSecurityTokenId] = useState(null);

  const [level1InitialValues, setLevel1InitialValues] = useState({});
  const [level2InitialValues, setLevel2InitialValues] = useState({});

  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);

  const navigate = useNavigate();
  const setCreatedTokenId = useBoundStore((state) => state?.securityTokenSlice?.setCreatedTokenId);
  const createdTokenId = useBoundStore((state) => state?.securityTokenSlice?.createdTokenId);

  const { handleSubmit } = methods;
  const formValues = methods.watch();
  const { id } = useParams();

  const steps = ['Create Token', 'Cusomize Token', 'Review & Deploy'];

  const tokenDetails = [
    {
      inputs: [
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter the name of the token offering',
          // requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Image',
          state: 'headerimage',
          placeholder: 'Enter the company name',
          // requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Token Icon',
          state: 'icon_url',
          placeholder: 'Enter the company name',
          // requiredForNextStep: true,
        },
      ],
    },
  ];

  const createTokenInputs = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name of the Token Offering',
          state: 'name',
          // requiredForNextStep: true,
          placeholder: 'Enter the name of the token offering',
        },
        {
          type: 'currencyInput',
          label: 'Amount of the Raise',
          state: 'amount',
          // requiredForNextStep: true,
          placeholder: 'Enter the total amount to be raised',
        },
        {
          type: 'currencyInput',
          label: 'Minimum Investment by an Investor',
          state: 'min_investment_usd',
          // requiredForNextStep: true,
          placeholder: 'Enter the minimum investment amount',
          halfWidth: true,
        },
        {
          type: 'currencyInput',
          label: 'Maximum Investment by an Investor',
          state: 'max_investment_usd',
          // requiredForNextStep: true,
          placeholder: 'Enter the maximum investment amount',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of Investors Allowed Worldwide',
          state: 'max_investors_worldwide',
          // requiredForNextStep: true,
          placeholder: 'Enter the maximum number of investors worldwide',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Non-accredited Investors',
          state: 'max_non_accredited_investors',
          // requiredForNextStep: true,
          placeholder: 'Enter the limit for non-accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Accredited Investors',
          state: 'max_accredited_investors',
          // requiredForNextStep: true,
          placeholder: 'Enter the limit for accredited investors',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Max Number of US Investors',
          state: 'max_us_investors',
          // requiredForNextStep: true,
          placeholder: 'Enter the limit for US investors',
          halfWidth: true,
        },
        {
          type: 'percentSlider',
          label: 'Max Percentage of US Investors',
          state: 'max_us_investor_percentage',
          // requiredForNextStep: true,
          placeholder: 'Select the percentage of total holders',
        },
        {
          type: 'numberInput',
          label: 'Lockout period for US Investors (months)',
          state: 'holding_period_days_us',
          // requiredForNextStep: true,
          placeholder: 'Enter the time period until security becomes tradeable',
          halfWidth: true,
        },
        {
          type: 'numberInput',
          label: 'Lockout period for Non US Investors (months)',
          state: 'holding_period_days_non_us',
          // requiredForNextStep: true,
          placeholder: 'Enter the timeframe for secondary market trading',
          halfWidth: true,
        },
        {
          type: 'dropdown',
          label: 'Regulation Type',
          state: 'offering_type',
          // requiredForNextStep: true,
          placeholder: 'Select the applicable regulation (e.g., Reg D, A, S, CF, Non-regulated)',
          options: [
            { label: 'Reg A', value: 'Reg A' },
            { label: 'Reg D', value: 'Reg D' },
            { label: 'Reg CF', value: 'Reg CF' },
            { label: 'Non Regulated', value: 'Non Regulated' },
          ],
          halfWidth: true,
          defaultValue: formValues?.offering_type,
        },
        {
          type: 'dropdown',
          label: 'Accreditation Process',
          state: 'accreditation_process',
          // requiredForNextStep: true,
          placeholder: 'Enter the type of investor',
          options: [
            { label: 'Self Certified', value: 'Self Certified' },
            {
              label: 'Third Party Verification Required',
              value: 'Third Party Verification Required',
            },
          ],
          halfWidth: true,
          defaultValue: formValues?.accreditation_process,
        },

        {
          type: 'calendar',
          label: 'When Does the Offer Start?',
          state: 'start_date',
          // requiredForNextStep: true,
          placeholder: 'Select the offer start date',
          halfWidth: true,
        },
        {
          type: 'calendar',
          label: 'When Does the Offer End?',
          state: 'closing_date',
          // requiredForNextStep: true,
          placeholder: 'Select the offer end date',
          halfWidth: true,
        },
      ],
    },
  ];

  const mockData = {
    icon_url: formValues?.icon_url?.hostedUrl || formValues?.icon_url,
    title: formValues?.name,
    description: formValues?.description,
    img: formValues?.headerimage?.hostedUrl || formValues?.headerimage,
    raisedObj: {
      investmentGoal: formValues?.amount,
      minInvestment: formValues?.min_investment_usd,
      investmentFinalDate: formValues?.closing_date,
    },
  };

  const securityToken = {
    max_supply: formValues?.amount,
    kyc_required: true,
  };

  const progressRaised = 0;

  const mockFormData = [
    {
      title: 'Offering Details',
      content: [
        { label: 'Funding Goal', value: '$' + formValues?.amount?.toLocaleString(), link: '' },
        {
          label: 'Security Type',
          value: formValues?.security_type,
          link: ' ',
        },
        {
          label: 'Min Investment',
          value: '$' + formValues?.min_investment_usd?.toLocaleString(),
          link: '',
        },

        {
          label: 'Max Investment',
          value: '$' + formValues?.max_investment_usd?.toLocaleString(),
          link: '',
        },
        {
          label: 'Closing Date',
          value: `${spacetime(formValues?.closing_date).format('{month} {date-ordinal}, {year}')}`,
          link: '',
        },
        { label: 'Offering Type', value: formValues?.offering_type, link: '' },
      ],
    },
  ];

  const level1InitialValuesObj = {
    name: formValues.name,
    amount: formValues.amount,
    min_investment_usd: formValues.min_investment_usd,
    max_investment_usd: formValues.max_investment_usd,
    max_investors_worldwide: formValues.max_investors_worldwide,
    max_non_accredited_investors: formValues.max_non_accredited_investors,
    max_accredited_investors: formValues.max_accredited_investors,
    max_us_investors: formValues.max_us_investors,
    max_us_investor_percentage: formValues.max_us_investor_percentage,
    holding_period_days_us: formValues.holding_period_days_us,
    holding_period_days_non_us: formValues.holding_period_days_non_us,
    offering_type: formValues.offering_type,
    accreditation_process: formValues.accreditation_process,
    start_date: formValues.start_date,
    closing_date: formValues.closing_date,
  };

  const level2InitialValuesObj = {
    description: formValues.description,
    headerimage: formValues.headerimage,
    icon_url: formValues.icon_url,
  };

  const handleGetRaiseById = async () => {
    try {
      const {
        data: { getRaiseById: getRaiseByIdRes },
      } = await client.query({
        query: GetRaiseByIdDocument,
        variables: { id: id },
      });
      console.log({ getRaiseByIdRes, trueFalse: !!getRaiseByIdRes?.security_token?.raise_id });

      methods.setValue('name', getRaiseByIdRes?.name);
      methods.setValue('amount', getRaiseByIdRes?.amount);
      methods.setValue('min_investment_usd', getRaiseByIdRes?.min_investment_usd);
      methods.setValue('max_investment_usd', getRaiseByIdRes?.max_investment_usd);
      methods.setValue('max_investors_worldwide', getRaiseByIdRes?.max_investors_worldwide);
      methods.setValue(
        'max_non_accredited_investors',
        getRaiseByIdRes?.max_non_accredited_investors,
      );
      methods.setValue('max_accredited_investors', getRaiseByIdRes?.max_accredited_investors);
      methods.setValue('max_us_investors', getRaiseByIdRes?.max_us_investors);
      methods.setValue('max_us_investor_percentage', getRaiseByIdRes?.max_us_investor_percentage);
      methods.setValue('holding_period_days_us', getRaiseByIdRes?.holding_period_days_us);
      methods.setValue('holding_period_days_non_us', getRaiseByIdRes?.holding_period_days_non_us);
      methods.setValue('offering_type', getRaiseByIdRes?.offering_type);
      methods.setValue('accreditation_process', getRaiseByIdRes?.accreditation_process);
      methods.setValue('start_date', new Date(getRaiseByIdRes?.start_date));
      methods.setValue('closing_date', new Date(getRaiseByIdRes?.closing_date));
      console.log({
        createdTokenId,
        id,
        getRaiseByIdRes: !!getRaiseByIdRes?.security_token?.raise_id,
        currentLevel,
      });

      if (!!getRaiseByIdRes?.security_token?.raise_id) {
        console.log('SETCURRENTLEVEL 3');
        setCurrentLevel(3);
        setScreenLoading(false);
        setSecurityTokenId(getRaiseByIdRes?.security_token?.raise_id);
      } else if (`${id}` == `${createdTokenId}`) {
        console.log('SETCURRENTLEVEL 3');
        setCurrentLevel(3);
        setScreenLoading(false);
      }
      setGetRaiseByIdCompleted(true);
    } catch (err) {
      console.log({ err });
      setScreenLoading(false);
      setGetRaiseByIdCompleted(true);
    }
  };

  const handleUpdateRaiseById = async (data) => {
    try {
      console.log({ data });
      const { description, icon_url, headerimage } = data;
      setButtonLoading(true);

      const {
        data: { updateRaiseById: updateRaiseByIdRes },
      } = await client.mutate({
        mutation: UpdateRaiseByIdDocument,
        variables: {
          id: id,
          input: {
            icon_url: icon_url.hostedUrl,
            image_url: headerimage.hostedUrl,
            description,
            site_builder: {
              description,
              icon_url: icon_url.hostedUrl,
              headerimage: headerimage.hostedUrl,
              ...data,
            },
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ updateRaiseByIdRes });
      setButtonLoading(false);
    } catch (err) {
      console.log({ err });
      setButtonLoading(false);
    }
  };

  const handleGetFormByRaiseID = async () => {
    try {
      const {
        data: { getFormByRaiseId: getFormByRaiseIdRes },
      } = await client.query({
        query: GetFormByRaiseIdDocument,
        variables: { id: id, form: 'site_builder' }, // COME BACK TO THIS
      });
      console.log({ getFormByRaiseIdRes });

      methods.reset(getFormByRaiseIdRes);

      methods.setValue('description', getFormByRaiseIdRes?.description);
      methods.setValue('icon_url', getFormByRaiseIdRes?.icon_url);
      methods.setValue('headerimage', getFormByRaiseIdRes?.headerimage);
      setTeamFormData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'team',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );
      setAdvisorFormData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'advisors',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );
      setFAQData(
        extractDataByKeyword(
          getFormByRaiseIdRes,
          questionAnswerInputs,
          questionAnswerOrder,
          'FAQ_',
        ).map((item) => ({
          inputs: item,
          sectionClassName: innerSectionClassName,
          callOnBlur: handleUpdateRaiseById,
        })),
      );

      console.log({
        extractDataByKeyword: extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'advisors',
        ),
        extractDataByKeyword2: extractDataByKeyword(
          getFormByRaiseIdRes,
          nameBioPhotoInputs,
          nameBioPhotoOrder,
          'team',
        ),
        extractDataByKeyword3: extractDataByKeyword(
          getFormByRaiseIdRes,
          questionAnswerInputs,
          questionAnswerOrder,
          'FAQ_',
        ),
      });

      if (currentLevel !== 3) {
        if (getFormByRaiseIdRes === null) {
          console.log('SETCURRENTLEVEL 1', { currentLevel });
          setCurrentLevel(1);
          setScreenLoading(false);
        } else {
          console.log('SETCURRENTLEVEL 2', { currentLevel });
          setCurrentLevel(2);
          setScreenLoading(false);
        }
      }
    } catch (err) {
      setScreenLoading(false);
      console.log({ err });
    }
  };

  const handleCreateRaise = async (data, update) => {
    setButtonLoading(true);
    try {
      const {
        max_non_accredited_investors,
        max_accredited_investors,
        max_us_investors,
        max_investors_worldwide,
        start_date,
        holding_period_days_us,
        holding_period_days_non_us,
        closing_date,
        max_us_investor_percentage,
        min_investment_usd,
        max_investment_usd,
      } = data;

      const data_ = { ...data };
      console.log({ data_ });

      delete data_.description;
      delete data_.headerimage;
      delete data_.icon_url;

      delete data_.offeringAgreementsImages;
      delete data_.problemSectionToggled;
      delete data_.solutionSectionToggled;
      delete data_.productSectionToggled;
      delete data_.tractionSectionToggled;
      delete data_.businessModelSectionToggled;
      delete data_.marketSectionToggled;
      delete data_.competitionSectionToggled;
      delete data_.visionModelSectionToggled;
      delete data_.investorsSectionToggled;
      delete data_.advisorToggled;
      delete data_.teamToggled;
      delete data_.FAQsSectionToggled;
      delete data_.socialLinksSectionToggled;

      const vars: any = {
        input: {
          company_id: selectedCompany.id,
          ...data_,
          start_date: start_date.toISOString(),
          holding_period_days_us: holding_period_days_us * 30,
          holding_period_days_non_us: holding_period_days_non_us * 30,
          closing_date: closing_date.toISOString(),
          max_investors_worldwide: `${max_investors_worldwide}`,
          max_non_accredited_investors: `${max_non_accredited_investors}`,
          max_accredited_investors: `${max_accredited_investors}`,
          max_us_investors: `${max_us_investors}`,
          max_us_investor_percentage: `${max_us_investor_percentage[0] || max_us_investor_percentage}`,
          min_investment_usd: `${min_investment_usd}`,
          max_investment_usd: `${max_investment_usd}`,
        },
      };
      const shouldUpdate = update && id;
      if (shouldUpdate) {
        vars.id = id;
      }
      console.log({ vars, max_us_investor_percentage });
      const {
        data: { createRaise: createRaiseRes },
      } = await client.mutate({
        mutation: shouldUpdate ? UpdateRaiseByIdDocument : CreateRaiseDocument,
        variables: vars,
      });
      console.log({ createRaiseRes });
      if (!id) {
        navigate(createRaiseRes.id);
      }
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      console.log({ err });
    }
  };

  const handleCreateSecurityToken = async () => {
    try {
      setButtonLoading(true);
      const {
        data: { createSecurityToken: createSecurityTokenRes },
      } = await client.mutate({
        mutation: CreateSecurityTokenDocument,
        variables: { input: { raise_id: parseInt(`${id}`) } },
      });
      console.log({ createSecurityTokenRes });
      setButtonLoading(false);
      setCongratsModalOpen(!congratsModalOpen);
      setCreatedTokenId(id);
    } catch (createSecurityTokenErr) {
      console.log({ createSecurityTokenErr });
      setButtonLoading(false);
    }
  };

  const renderTokenView = () => {
    return (
      <>
        <div className="mb-[16px] flex">
          <img
            src={mockData.icon_url}
            className="mr-[16px] size-[72px] rounded-[10px] border border-text-color object-cover"
          />
          <div className="flex flex-col">
            <h1 className="mb-[8px] text-[32px] font-[400]">{mockData.title}</h1>
            <p className="text-[14px]">{mockData.description}</p>
          </div>
        </div>
        <div className="mb-[50px] flex flex-col sm:flex-row">
          <img
            src={mockData.img}
            className="mr-[32px] h-[375px] w-full max-w-[590px] rounded-[25px] object-cover sm:mr-[30px] sm:w-full"
            style={{ objectFit: 'cover' }}
          />
          <div
            className="bg-card-color h-[375px] min-w-[400px] max-w-[400px] rounded-[16px] p-[24px]
          shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] "
          >
            <div className="mb-[12px] flex justify-between">
              <p className="text-[14px] font-[300]">Raising</p>
              <div className="rounded-[15px] bg-gray-300 p-[0px_12px]">
                <p className="font-[500]">${securityToken?.max_supply}</p>
              </div>
            </div>
            <h1 className="mb-[12px] text-[28px]">
              $0
              <span className="ml-[10px] text-[18px]">Raised</span>
            </h1>
            <div className="mb-[16px] flex">
              <div className="h-[16px] w-full overflow-hidden rounded-[10px] bg-gray-300">
                <div
                  style={{ width: `${progressRaised}%` }}
                  className="h-[16px] rounded-[10px] bg-primary"
                />
              </div>
              <p className="ml-[10px] text-[14px]">{progressRaised}%</p>
            </div>
            <div className="py-[16px]">
              <p className="text-[16px] font-[300]">
                <span className="text-[22px] font-[400]">
                  {daysUntilDate(`${mockData?.raisedObj?.investmentFinalDate}`)}
                  {/* {`${mockData?.raisedObj?.investmentFinalDate}`} */}
                </span>
              </p>
            </div>
            <button
              className="h-[50px] w-full rounded-[100px] bg-primary text-white opacity-60"
              onClick={() => {}}
            >
              Invest Now
            </button>
            <p className="mt-[7px] text-center text-[14px] font-[300]">
              <strong>{`$${mockData.raisedObj.minInvestment?.toLocaleString()}.00`}</strong> min.
              investment
            </p>
            {!!(currentLevel == 3 && securityTokenId) && (
              <button
                className="mt-[20px] flex w-full items-center justify-center rounded-[5px] border-2 border-text-color p-[8px] text-[18px] font-semibold"
                onClick={() => {
                  navigate(`/edit-security-token/${id}`);
                }}
              >
                <Pencil2Icon className="mr-3 size-[16px] stroke-text-color" />
                EDIT
              </button>
            )}
          </div>
        </div>
        <div className="mb-[80px] flex flex-col justify-between sm:flex-row">
          {mockFormData.map(({ title, content }, index) => (
            <div
              className="mb-[20px] w-full rounded-[16px] border border-text-color p-[24px] sm:mb-0 sm:w-[59%]"
              key={`form_display_${index}`}
            >
              <h5 className="border-b border-text-color pb-[16px]">{title}</h5>
              <div className="mt-[16px] flex flex-wrap gap-[10px] ">
                {content
                  ?.filter((item) => item.value)
                  ?.map(({ label, value, link }, index2) => (
                    <div key={`keyVal_${index2}`} className="min-w-[32%]">
                      <label className="text-[13px]">{label}</label>
                      {link?.length ? (
                        <a
                          href={`${link}`}
                          target="_blank"
                          className="link flex text-[16px] text-[#369EDA] underline"
                          rel="noreferrer"
                        >
                          <Contract className="mr-[5px]" /> {value}
                        </a>
                      ) : (
                        <p className="text-[16px]">
                          {link && <Contract />} {value}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const preprocessValues = (values) => {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        value instanceof Date ? value.toISOString() : value,
      ]),
    );
  };

  const haveFormValuesChanged = (initialValues, currentValues) => {
    const processedInitialValues = preprocessValues(initialValues);
    const processedCurrentValues = preprocessValues(currentValues);
    return JSON.stringify(processedInitialValues) !== JSON.stringify(processedCurrentValues);
  };

  const handleContinue = async (data) => {
    try {
      if (currentLevel === 0) {
        await handleCreateRaise(
          data,
          haveFormValuesChanged(level1InitialValues, level1InitialValuesObj),
        );
        console.log({
          VALUECHANGED: haveFormValuesChanged(level1InitialValues, level1InitialValuesObj),
        });
      } else if (currentLevel === 1) {
        console.log('LEVEL 1');
        if (haveFormValuesChanged(level2InitialValues, level2InitialValuesObj)) {
          await handleUpdateRaiseById(data);
        }
      } else if (currentLevel == 2) {
        await handleCreateSecurityToken();
        return;
      }
      setCurrentLevel(currentLevel + 1);
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    if (id) {
      handleGetRaiseById();
    } else {
      setScreenLoading(false);
    }
  }, []);

  useEffect(() => {
    // Ensure this only gets called after getRaiseById
    if (getRaiseByIdCompleted) {
      handleGetFormByRaiseID();
    }
  }, [getRaiseByIdCompleted]);

  useEffect(() => {
    console.log({ currentLevel });

    if (currentLevel !== 0) {
      setLevel1InitialValues(level1InitialValuesObj);
    }
    if (currentLevel > 1) {
      setLevel2InitialValues(level2InitialValuesObj);
    }
  }, [currentLevel]);

  useEffect(() => {
    console.log({ mockData });
  }, [mockData]);

  // NEW STUFF

  const sectionClassName = 'relative mb-[32px] grid w-full gap-[16px] rounded-[8px] p-[0px]';

  const innerSectionClassName =
    'bg-[#f9f9f9] p-[24px] flex flex-col gap-[24px] w-full rounded-[16px] mt-[24px]';

  // const handleUpdateRaiseById = async (data) => {
  //   try {
  //     console.log({
  //       data,
  //       countKeysAndValues: countKeysAndValues(data, offeringAndProblemStateToExclude),
  //     });
  //     const {
  //       data: { updateRaiseById: updateRaiseByIdRes },
  //     } = await client.mutate({
  //       mutation: UpdateRaiseByIdDocument,
  //       variables: {
  //         id,
  //         input: {
  //           site_builder: { totalNumberOfFields: 38, ...data },
  //         },
  //       },
  //       context: {
  //         clientName: ClientName.Marketplace,
  //       },
  //     });
  //     console.log({ updateRaiseByIdRes });
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };

  // const handleGetFormByRaiseID = async () => {
  //   try {
  //     const {
  //       data: { getFormByRaiseId: getFormByRaiseIdRes },
  //     } = await client.query({
  //       query: GetFormByRaiseIdDocument,
  //       variables: { id, form: 'site_builder' }, // COME BACK TO THIS
  //     });
  //     // console.log({
  //     //   getFormByRaiseIdRes,
  //     //   countKeysAndValues: countKeysAndValues(
  //     //     getFormByRaiseIdRes,
  //     //     offeringAndProblemStateToExclude,
  //     //   ),
  //     // });
  //     methods.reset(getFormByRaiseIdRes);
  //     setTeamFormData(
  //       extractDataByKeyword(
  //         getFormByRaiseIdRes,
  //         nameBioPhotoInputs,
  //         nameBioPhotoOrder,
  //         'team',
  //       ).map((item) => ({
  //         inputs: item,
  //         sectionClassName: innerSectionClassName,
  //         callOnBlur: handleUpdateRaiseById,
  //       })),
  //     );
  //     setAdvisorFormData(
  //       extractDataByKeyword(
  //         getFormByRaiseIdRes,
  //         nameBioPhotoInputs,
  //         nameBioPhotoOrder,
  //         'advisors',
  //       ).map((item) => ({
  //         inputs: item,
  //         sectionClassName: innerSectionClassName,
  //         callOnBlur: handleUpdateRaiseById,
  //       })),
  //     );
  //     setFAQData(
  //       extractDataByKeyword(
  //         getFormByRaiseIdRes,
  //         questionAnswerInputs,
  //         questionAnswerOrder,
  //         'FAQ_',
  //       ).map((item) => ({
  //         inputs: item,
  //         sectionClassName: innerSectionClassName,
  //         callOnBlur: handleUpdateRaiseById,
  //       })),
  //     );
  //     // console.log({
  //     //   extractDataByKeyword: extractDataByKeyword(
  //     //     getFormByRaiseIdRes,
  //     //     nameBioPhotoInputs,
  //     //     nameBioPhotoOrder,
  //     //     'advisors',
  //     //   ),
  //     //   extractDataByKeyword2: extractDataByKeyword(
  //     //     getFormByRaiseIdRes,
  //     //     nameBioPhotoInputs,
  //     //     nameBioPhotoOrder,
  //     //     'team',
  //     //   ),
  //     //   extractDataByKeyword3: extractDataByKeyword(
  //     //     getFormByRaiseIdRes,
  //     //     questionAnswerInputs,
  //     //     questionAnswerOrder,
  //     //     'FAQ_',
  //     //   ),
  //     // });
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };

  const formDataOfferingAgreements = [
    {
      title: '',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      inputs: [
        // {
        //   type: 'imageUpload',
        //   noCrop: true,
        //   label: 'Header Image (Main image on offering page)',
        //   state: 'headerimage',
        //   noCrop: true,
        // },
        {
          type: 'input',
          label: 'Link to Regulatory Document',
          state: 'regulatoryDocumentLink',
        },
      ],
    },
  ];

  const formDataProblemSolution = [
    {
      title: 'Problem',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'problemSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'problemHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'problemDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Solution',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'solutionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'solutionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'solutionDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Solution Image',
          state: 'solutionImageUpload',
        },
      ],
    },
    {
      title: 'Product',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'productSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'productHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'productDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Product Image',
          state: 'productImageUpload',
        },
      ],
    },
    {
      title: 'Traction',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'tractionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'tractionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'tractionDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Business Model',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'businessModelSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'businessModelHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'businessModelDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Market',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'marketSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'marketHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'marketDescription',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          noCrop: true,
          label: 'Market Image',
          state: 'marketImageUpload',
        },
      ],
    },
    {
      title: 'Competition',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'competitionSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'competitionHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'competitionDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Vision Model',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'visionModelSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'visionModelHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'visionModelDescription',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Investors',
      callOnBlur: handleUpdateRaiseById,
      sectionClassName,
      hasToggle: true,
      toggleState: 'investorsSectionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'investorsHeader',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: "Investor's description",
          state: 'investorsDescription',
          placeholder: "Enter investor's description",
        },
      ],
    },
  ];

  const generateFormDataByMember = useCallback(
    (title = '', typeOfMember = '', noInputs = false) => [
      {
        title,
        sectionClassName: 'w-full gap-[24px] flex flex-col',
        hasToggle: true,
        toggleState: `${typeOfMember}Toggled`,
        callOnBlur: handleUpdateRaiseById,
        inputs: noInputs
          ? []
          : [
              {
                type: 'input',
                label: 'Header',
                state: `${typeOfMember}_header`,
                placeholder: 'Enter header',
              },
              {
                type: 'textarea',
                label: `${typeOfMember}'s description`,
                state: `${typeOfMember}_description`,
                placeholder: "Enter advisor's description",
              },
            ],
      },
    ],
    [],
  );

  const additionalAdvisorInputs = (index) => [
    {
      type: 'input',
      label: "Advisor's name",
      state: `advisorsName_${index}`,
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Advisor's bio",
      state: `advisorsBio_${index}`,
      placeholder: "Enter advisor's description",
    },
    {
      type: 'imageUpload',
      noCrop: true,
      label: "Advisor's photo",
      state: `advisorsPhoto_${index}`,
    },
  ];
  const additionalTeamInputs = (index) => [
    {
      type: 'input',
      label: "Team member's name",
      state: `teamsName_${index}`,
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Team member's bio",
      state: `teamsBio_${index}`,
      placeholder: "Enter Team's description",
    },
    {
      type: 'imageUpload',
      noCrop: true,
      label: "Team member's photo",
      state: `teamsPhoto_${index}`,
    },
  ];
  const additionalFAQInputs = (index) => [
    {
      type: 'input',
      label: 'Question',
      state: `FAQ_Question_${index}`,
      placeholder: 'Enter your question',
    },
    {
      type: 'textarea',
      label: 'Answer',
      state: `FAQ_Answer_${index}`,
      placeholder: 'Enter your answer',
    },
  ];

  const [advisorFormData, setAdvisorFormData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalAdvisorInputs(0),
    },
  ]);
  const [teamFormData, setTeamFormData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalTeamInputs(0),
    },
  ]);
  const [FAQData, setFAQData] = useState<any>([
    {
      sectionClassName: innerSectionClassName,
      callOnBlur: handleUpdateRaiseById,
      inputs: additionalFAQInputs(0),
    },
  ]);

  const formDataSocials = [
    {
      title: ' Social Links',
      sectionClassName,
      hasToggle: true,
      toggleState: 'socialLinksSectionToggled',
      callOnBlur: handleUpdateRaiseById,
      inputs: [
        {
          type: 'input',
          state: 'linkedInURL',
          placeholder: 'LinkedIn URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'instagramURL',
          placeholder: 'Instagram URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'facebookURL',
          placeholder: 'Facebook URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'twitterURL',
          placeholder: 'Twitter URL',
          halfWidth: true,
        },
      ],
    },
  ];

  return (
    <div className="relative mx-[0px] mt-[-30px] flex size-full max-w-[1200px] flex-col items-center gap-[50px] ">
      {screenLoading ? (
        <div className="flex min-h-[95px] w-full items-center justify-center">
          <div className="mt-[30%] size-[50px] animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          {currentLevel !== 3 && <ProgressLevelsBar steps={steps} currentLevel={currentLevel} />}
          <form
            className="relative w-full max-w-[1000px] pb-[30px]"
            onSubmit={handleSubmit(handleContinue)}
          >
            {/* <div className="relative flex"> */}
            <div className="sticky top-[-30px] z-10 flex bg-background pt-[20px]">
              <h1 className="mb-[30px] w-full text-2xl font-semibold">{steps[currentLevel]}</h1>
              {currentLevel !== 3 && (
                <div className="w-full">
                  <BackNext
                    numOfLevels={3}
                    finalBtnText={'Approve & Deploy'}
                    currentLevel={currentLevel}
                    setCurrentLevel={setCurrentLevel}
                    finalBtnClick={() => {}}
                    loading={buttonLoading}
                  />
                </div>
              )}
            </div>

            {currentLevel == 0 && (
              <>
                {/* <h1 className="mb-[30px] w-full text-2xl font-semibold">Create Your Token</h1> */}
                <WrappedInputSection methods={methods} data={createTokenInputs} />
              </>
            )}

            {currentLevel == 1 && (
              <>
                {/* <h1 className="mb-[30px] w-full text-2xl font-semibold">Cusomize Your Token</h1> */}
                <div className="mb-[30px] flex w-full gap-[30px]">
                  <div className="w-full ">
                    <WrappedInputSection
                      methods={methods}
                      data={[{ inputs: tokenDetails[0].inputs.slice(0, 2) }]}
                    />
                  </div>
                  <div className="w-[400px] ">
                    <WrappedInputSection
                      methods={methods}
                      data={[{ inputs: tokenDetails[0].inputs.slice(2, 3) }]}
                    />
                  </div>
                </div>
                <WrappedInputSection methods={methods} data={formDataOfferingAgreements} />
                <WrappedInputSection methods={methods} data={formDataProblemSolution} />
                <div className={cn(sectionClassName)}>
                  <WrappedInputSection
                    methods={methods}
                    data={generateFormDataByMember('Investors and Advisors', 'advisor')}
                  />
                  <WrappedInputSection methods={methods} data={advisorFormData} />
                  <AddButton
                    onClick={(e) => {
                      setAdvisorFormData([
                        ...advisorFormData,
                        {
                          sectionClassName: innerSectionClassName,
                          callOnBlur: handleUpdateRaiseById,
                          inputs: additionalAdvisorInputs(advisorFormData.length),
                        },
                      ]);
                    }}
                  />
                </div>
                <div className={cn(sectionClassName)}>
                  <WrappedInputSection
                    methods={methods}
                    data={generateFormDataByMember('Team', 'team')}
                  />
                  <WrappedInputSection methods={methods} data={teamFormData} />
                  <AddButton
                    onClick={(e) => {
                      setTeamFormData([
                        ...teamFormData,
                        {
                          sectionClassName: innerSectionClassName,
                          callOnBlur: handleUpdateRaiseById,
                          inputs: additionalTeamInputs(teamFormData.length),
                        },
                      ]);
                    }}
                  />
                </div>
                <div className={cn(sectionClassName)}>
                  <WrappedInputSection
                    methods={methods}
                    data={generateFormDataByMember('FAQ', 'FAQ', true)}
                  />
                  <WrappedInputSection methods={methods} data={FAQData} />
                  <AddButton
                    onClick={(e) => {
                      setFAQData([
                        ...FAQData,
                        {
                          sectionClassName: innerSectionClassName,
                          callOnBlur: handleUpdateRaiseById,
                          inputs: additionalFAQInputs(FAQData.length),
                        },
                      ]);
                    }}
                  />
                </div>
                <WrappedInputSection methods={methods} data={formDataSocials} />
              </>
            )}
            {currentLevel == 2 && (
              <>
                {/* <h1 className="mb-[30px] w-full text-2xl font-semibold">Review & Deploy</h1> */}
                {renderTokenView()}
              </>
            )}
            {currentLevel == 3 && renderTokenView()}
            {/* {currentLevel !== 3 && (
              <div className="mt-[30px]">
                <BackNext
                  numOfLevels={3}
                  finalBtnText={'Approve & Deploy'}
                  currentLevel={currentLevel}
                  setCurrentLevel={setCurrentLevel}
                  finalBtnClick={() => {}}
                  loading={buttonLoading}
                />
              </div>
            )} */}
          </form>
          <CongratsModal
            title="Congratulations"
            subTitle="You're all set! Your security token is being deployed, please be patient while the blockchain transactions take place."
            open={congratsModalOpen}
            setOpen={() => {
              setCongratsModalOpen(!congratsModalOpen);
            }}
            onConfirm={() => {}}
          />
        </>
      )}
    </div>
  );
}

export default SecurityTokenView;
