import { format } from 'date-fns';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { signOut } from 'firebase/auth';

import config from '@/config';
import useBoundStore from '@/store';
import { ObservableSubscription } from '@apollo/client';

import {
  GetSelectedProjectDocument,
  GetTokenDocument,
  GetUserDocument,
  GetUserProjects_MinimalDocument,
  Project,
  SetSelectedProjectDocument,
  UserExistsDocument,
} from '../__generated__/dashboard/graphql';
import {
  Company,
  GetSelectedCompanyDocument,
  SetSelectedCompanyDocument,
} from '../__generated__/marketplace/graphql';
import { subscribeToProjectChannel } from '../api/subscriptions';
import { client, ClientName, getApolloClient } from '../apollo';
import { auth } from '../firebase';
import { decodeToken, insertUrlParam, redirectToProjectParam } from '../utils/auth';

export const isExpired = (decodedToken): boolean /* true = expired */ => {
  if (!decodedToken) return true;
  const exp = (decodedToken as { exp: number })?.exp;
  if (!exp) return true;
  const now = Math.floor(Date.now() / 1000);
  const expired = exp < now;
  return expired;
};

export const handleSetDecodeTokenAndExp = (): boolean /* true = expired */ => {
  const adminToken = localStorage.getItem('adminToken');
  if (!adminToken) return true;
  const decodedToken = decodeToken(adminToken, false, false);
  const setDecodedTokenData = useBoundStore.getState().authSlice.setDecodedTokenData;
  const setTokenExpiresAt = useBoundStore.getState().authSlice.setTokenExpiresAt;
  setDecodedTokenData(decodedToken?.data ?? null);
  setTokenExpiresAt(decodedToken?.exp ?? null);
  return isExpired(decodedToken);
};

export const fetchProjects = async (): Promise<Project[] | undefined> => {
  const projectSlice = useBoundStore.getState().projectSlice;
  // TODO: combine awaits into Promise.all
  const {
    data: { getUserProjects },
  } = await client.query({
    query: GetUserProjects_MinimalDocument,
    context: {
      clientName: ClientName.Dashboard,
    },
  });
  const projects = getUserProjects as Project[];
  console.debug('🦊 | fetchProjectsAndSelectedProject | projects:', projects);
  if (projects) {
    projectSlice.setProjects(projects);
    return projects;
  }
};

export const getProjectNameFromUrl = (): string | undefined => {
  const queryParams = new URLSearchParams(window.location.search);
  const petNameFromUrl = queryParams.get(config.projectQueryParamsKey);
  return petNameFromUrl || undefined;
};

export const getCompanyIdFromUrl = (): string | undefined => {
  const queryParams = new URLSearchParams(window.location.search);
  const companyFromUrl = queryParams.get(config.companyQueryParamsKey);
  return companyFromUrl || undefined;
};

export const handleSetSelectedCompany = async (
  selectedCompanyId: any, // old version was project.id so from db might be id still
) => {
  const companies: Company[] = useBoundStore.getState().companySlice.companies;
  const companyIdFromUrl = getCompanyIdFromUrl();
  console.log({ companyIdFromUrl });
  let companyFromId;
  if (companyIdFromUrl) {
    companyFromId = companies?.find((comp) => `${comp.id}` === companyIdFromUrl);
  }
  let companyToSelect;
  // set first project as default (call api and set store)
  if (
    (!selectedCompanyId && companies?.length) ||
    (companyFromId && selectedCompanyId !== companyFromId.id)
  ) {
    // never selected project or has url param that doesn't match the current
    companyToSelect = companyFromId ?? companies[0];
    console.log({ companyToSelect });
    if (!companyToSelect?.id) return;
    return handleSelectProject(companyToSelect.id); // api call
  } else if (selectedCompanyId && !companyFromId?.id && companies.length) {
    // no from URL but have currently selected on backend
    // old version was project.id
    const comp = companies.find(
      (c) => c.id?.toString() === selectedCompanyId || c.id === selectedCompanyId,
    );
    if (comp) {
      companyToSelect = comp;
      setLocalStorageMarketplaceGraphqlUrlAndRedirect(companyToSelect);
    }
  } else if (
    companyFromId &&
    (companyFromId?.id === selectedCompanyId || companyFromId?.id === selectedCompanyId)
  ) {
    // from URL is the same as currently selected on backend
    companyToSelect = companyFromId;
    setLocalStorageMarketplaceGraphqlUrlAndRedirect(companyToSelect);
  }
};

export const fetchCompaniesAndSelectedCompany = async () => {
  const fetchCompanies = useBoundStore.getState().companySlice.fetchCompanies;
  const getSelectedCompany = client.query({
    query: GetSelectedCompanyDocument,
  });
  const [
    companies,
    {
      data: { getSelectedCompany: getSelectedCompanyRes },
    },
  ] = await Promise.all([fetchCompanies(), getSelectedCompany]);
  await handleSetSelectedCompany(getSelectedCompanyRes);
};

export const logout = (navigate?) => {
  if (auth?.currentUser) {
    signOut(auth);
  }
  const authSlice = useBoundStore.getState().authSlice;
  const projectSlice = useBoundStore.getState().projectSlice;
  const assetsSlice = useBoundStore.getState().assetsSlice;
  const collectionsSlice = useBoundStore.getState().collectionsSlice;
  const companySlice = useBoundStore.getState().companySlice;

  // const eventsSlice = useBoundStore.getState().eventsSlice; // NOTE: i didn't see this slice so i'm going to comment this out for noww
  // const eventPassesSlice = useBoundStore.getState().eventPassesSlice; // NOTE: i didn't see this slice so i'm going to comment this out for noww
  const payoutProgramsSlice = useBoundStore.getState().payoutProgramsSlice;
  localStorage.clear();
  // TODO: might have to add all the other slices reset
  authSlice.reset();
  projectSlice.reset();
  assetsSlice.reset();
  collectionsSlice.reset();
  // eventsSlice.reset(); // NOTE: i didn't see this slice so i'm going to comment this out for noww
  // eventPassesSlice.reset(); // NOTE: i didn't see this slice so i'm going to comment this out for noww
  payoutProgramsSlice.reset();
  companySlice.reset();

  // NOTE: might be able to remove this redirect if the authorized layout setup works perfectly
  // for now since we are waiting for createUser from backend, just redirect
  if (navigate) {
    console.debug('navigating to /signin');
    navigate('/signin');
  }
};

export const checkUserExists = async (email) => {
  const userExistsRes = await client.query({
    query: UserExistsDocument,
    variables: { email: email },
    context: {
      clientName: ClientName.Dashboard,
    },
  });
  // const userExistsRes = await userExists({ variables: { email: email } });
  console.debug('🦊 | checkUserExists | userExistsRes:', userExistsRes);
  if (!userExistsRes?.data?.userExists) {
    throw Error(`User doesn't exist. Please contact admin.`);
  }
  if (userExistsRes?.error) throw userExistsRes.error;
};

export const fetchAdminToken = async (rawFireAccessToken?: string, projectName?: string) => {
  console.debug('🦊 | calling fetchAdminToken');
  const { data } = await client.query({
    query: GetTokenDocument,
    context: {
      clientName: ClientName.Dashboard,
      useFirebaseToken: true,
      rawFireAccessToken,
    },
    variables: { project: projectName || getProjectNameFromUrl() },
  });
  console.debug('🦊 | fetchAdminToken | data:', data);
  const {
    getToken: { token: accessToken, api_call_permisisons },
  } = data ?? {};
  // TODO: handle if firebase token expired?
  console.log({ api_call_permisisons });
  if (accessToken) {
    const adminToken = `${accessToken}`;
    localStorage.setItem('adminToken', adminToken);
    const api_call_permisisons_res = JSON.stringify(api_call_permisisons);
    localStorage.setItem('api_call_permisisons', api_call_permisisons_res);
    // TODO: set decoded token and set expired
  }
};

export const refetchAdminToken = async () => {
  console.debug('calling refetchAdminToken');
  const setRefetchingAdminToken = useBoundStore.getState().authSlice.setRefetchingAdminToken;
  try {
    setRefetchingAdminToken(true);
    await fetchAdminToken();
    handleSetDecodeTokenAndExp();
    setRefetchingAdminToken(false);
  } catch (err) {
    setRefetchingAdminToken(false);
    // TODO: handle when firebase token also expires
    console.error('refetchAccessToken error:', err, 'auth?.currentUSer', auth?.currentUser);
  }
};

export const reviveAuth = async (): Promise<ObservableSubscription | undefined> => {
  console.debug('😶 | reviveAuth | reviveAuth:');
  const authSlice = useBoundStore.getState().authSlice;
  const setIsCheckingAuth = authSlice.setIsCheckingAuth;
  const setAuthChecked = authSlice.setAuthChecked;
  const setUser = authSlice.setUser;
  let projectSubscription;

  try {
    // ********************* use real user [start] *********************************
    if (!localStorage.getItem('adminToken')) {
      setIsCheckingAuth(false);
      setAuthChecked(true);
      return;
    }
    // TODO: check token expiry
    const expired = handleSetDecodeTokenAndExp();
    if (expired) {
      await refetchAdminToken();
    }
    const userRes = await client.query({
      query: GetUserDocument,
      context: {
        clientName: ClientName.Dashboard,
      },
    });
    console.debug('🦊 | reviveAuth | userRes:', userRes);
    const user = userRes.data.getUser;
    setUser(user);

    // ONLY NEED COMPANIES ONE
    await fetchCompaniesAndSelectedCompany();
    projectSubscription = subscribeToProjectChannel();
    // ********************* use real user [end] *********************************
    setIsCheckingAuth(false);
    setAuthChecked(true);
    return projectSubscription;
  } catch (err) {
    setIsCheckingAuth(false);
    setAuthChecked(true);
  }
};

export const signInWithGoogle = async (): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();
  console.log('FIRED');
  const googleLoginRes: UserCredential = await signInWithPopup(auth, provider);
  console.log({ googleLoginRes });
  await checkUserExists(googleLoginRes.user.email);
  // const firebaseToken = `fire:${(googleLoginRes?.user as any)?.accessToken}`;
  await fetchAdminToken((googleLoginRes.user as any).accessToken);
  // localStorage.setItem('firebaseToken', firebaseToken);
  // console.debug('🦊 | signInWithGoogle | accessToken:', accessToken);
  return googleLoginRes;
};

export const signInWithPassword = async (email, password): Promise<UserCredential> => {
  const passwordLoginRes: any = await signInWithEmailAndPassword(auth, email, password);
  console.log({ passwordLoginRes });
  // const firebaseToken = `fire:${passwordLoginRes?.user?.accessToken}`;
  await fetchAdminToken((passwordLoginRes.user as any).accessToken);
  // localStorage.setItem('firebaseToken', firebaseToken);
  // console.debug('🦊 | signInWithGoogle | accessToken:', accessToken);
  return passwordLoginRes;
};

export const createFirebaseUserWithPassword = async (email, password): Promise<UserCredential> => {
  const userCreds: any = await createUserWithEmailAndPassword(auth, email, password);
  console.debug('🦊 | createFirebaseUserWithPassword | userCreds:', userCreds);
  // const firebaseToken = `fire:${userCreds?.user?.accessToken}`;
  await fetchAdminToken((userCreds.user as any).accessToken);
  // localStorage.setItem('firebaseToken', firebaseToken);
  // console.debug('🦊 | createFirebaseUserWithPassword | accessToken:', accessToken);
  return userCreds;
};

export const isRawFireTokenExpired = () => {
  const rawFireAccessToken = (auth?.currentUser as any)?.accessToken;
  if (!rawFireAccessToken) return true;
  return (auth?.currentUser as any)?.stsTokenManager?.isExpired;
};

export const getRawFireTokenExpiry = () => {
  if (!auth?.currentUser) return;
  const unixTime = (auth?.currentUser as any)?.stsTokenManager?.expirationTime;
  const formattedTime = format(unixTime, 'yyyy-MM-dd HH:mm:ss');
  return formattedTime;
};

const setLocalStorageMarketplaceGraphqlUrlAndRedirect = (company: Company) => {
  const setSelectedCompany = useBoundStore.getState().companySlice.setSelectedCompany;
  console.log({ company });
  setSelectedCompany(company);

  // localStorage.setItem('graphql_endpoint_url', config.graphql_endpoint_url as string);
  if (!company.id) return;

  // if (!urlInLocalBefore || urlInLocalBefore !== company.graphql_endpoint_url) {
  //   redirectToProjectParam(company.project_name as string); // COMEBACKTO
  // }
};

// vv this has api call. used onClick change projects
export const handleSelectProject = async (company_id: any) => {
  console.log({ company_id });
  if (!company_id) return;
  const promises = [
    client.mutate({
      mutation: SetSelectedCompanyDocument,
      variables: { company_id: company_id },
      context: { clientName: ClientName.Marketplace },
    }),
    // fetchAdminToken(undefined, company_id),
  ];
  await Promise.all(promises);
  const companies: Company[] = useBoundStore.getState().companySlice.companies;
  const company = companies.find((p) => p.id === company_id);
  if (!company) {
    console.error(`cannot find company with ${company_id} in the companys`);
    return;
  }
  console.log({ company_id });
  // redirectToProjectParam(company_id);
  setLocalStorageMarketplaceGraphqlUrlAndRedirect(company);
};
