import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Analytics from '@/assets/Analytics.svg?react';
import Campaigns2 from '@/assets/Campaigns.svg?react';
import ChartLine from '@/assets/ChartLine.svg?react';
import Coins from '@/assets/Coins.svg?react';
import File from '@/assets/File.svg?react';
import Gift from '@/assets/Gift.svg?react';
import HomeImg from '@/assets/Home.svg?react';
import Key from '@/assets/Key.svg?react';
import LogoutIcon from '@/assets/Logout.svg?react';
import MintWizard from '@/assets/Mintwizard.svg?react';
import Mysite from '@/assets/Mysite.svg?react';
import RealEstate from '@/assets/RealEstate3.svg?react';
import SettingsImg from '@/assets/Settings.svg?react';
import slateLogo from '@/assets/slateLogo.png';
import Userinfo from '@/assets/Userinfo.svg?react';
import OnboardingModal from '@/components/modals/onboard-modal';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { useToast } from '@/components/ui/use-toast';
import config from '@/config';
import { companyNameAndLogo } from '@/const';
import useRenewAdminTokenSetup from '@/hooks/use-renew-admin-token-setup';
import { Company, RestartUiDeploymentDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { handleSelectProject, logout, reviveAuth } from '@/lib/helpers/auth';
import { cn } from '@/lib/utils';
import useBoundStore, { StoreType } from '@/store';
import { selectIsLoggedIn } from '@/store/auth-slice';
import { selectMarketplaceClientIsReady } from '@/store/project-slice';
import { ObservableSubscription } from '@apollo/client';
import { SymbolIcon } from '@radix-ui/react-icons';

function CreateLayout({ pathname = '/' }) {
  const navigate = useNavigate();
  const [currentLevel, setCurrentLevel] = useState(0);

  const levelsFromPathname = {
    '/create-collection': ['Create a Collection', 'Collection Settings'],
    '/edit-collection': ['Edit Collection', 'Edit Settings'],
    '/create-event': ['Create an Event', 'Event Settings'],
    '/create-asset': ['Create an Asset', 'Additional Information', 'Settings', 'Preview & Approve'],
    '/edit-asset': ['Edit an Asset', 'Additional Information', 'Settings', 'Preview & Approve'],
    '/create-rewards': ['Select an audience', 'Select rewards', 'Review rewards'],
    '/create-company': ['Company Information', 'Contact Information'],
    // '/create-token': ['Token Details', 'Offering Page', 'Token Summary'],
    '/create-event-pass': [
      'Create Event Pass',
      'Pass Information',
      'Pass Settings',
      'Preview & Approve',
    ],
    '/create-exclusive-video': [
      'Create Exclusive Video',
      'Exclusive Video Details',
      'Preview & Approve',
    ],
    '/create-real-estate': [
      'Property information',
      'Property description',
      'Financial details',
      'Additional features',
      'Optional FAQ',
    ],
    '/edit-real-estate': [
      'Property information',
      'Property description',
      'Additional features',
      'Optional FAQ',
    ],
    '/': [],
  };

  return (
    <div className="flex h-screen flex-col bg-card">
      <div className="flex h-screen">
        <div className="relative box-border flex min-w-[330px] flex-col overflow-y-auto border p-[48px]">
          <button
            className="mb-[80px] flex items-center"
            onClick={() => {
              navigate('/home');
            }}
          >
            <img className="mr-[20px] w-8 rounded-md" src={companyNameAndLogo.logo} />
            <p className="text-xl font-semibold uppercase">{companyNameAndLogo.name}</p>
          </button>
          <div className="grid gap-[30px]">
            {levelsFromPathname[pathname].map((item, index) => (
              <div key={`${item}`} className="flex items-center">
                <h5
                  className={`mr-4 flex size-[40px] items-center justify-center rounded-full border text-lg font-semibold shadow-md ${
                    currentLevel >= index ? 'bg-primary text-white' : 'bg-background text-primary'
                  }
                  `}
                >
                  {index + 1}
                </h5>
                <p className="text-lg">{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-full flex-col overflow-y-scroll bg-background px-[40px] py-[48px]">
          <Outlet context={[currentLevel, setCurrentLevel, levelsFromPathname[pathname]]} />
        </div>
      </div>
    </div>
  );
}

function DashboardLayout() {
  const [disableRestartDeployment, setDisableRestartDeployment] = useState(false);
  const isLoggedIn = useBoundStore(selectIsLoggedIn);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const setSelectedProject = useBoundStore((state) => state.projectSlice.setSelectedProject);

  const [, rootLocation] = location.pathname.split('/');
  const navigate = useNavigate();
  const { toast } = useToast();

  const sideBarTabs = [
    { icon: HomeImg, name: 'Home', path: 'home', shown: true },
    { icon: ChartLine, name: 'Tokens', path: 'company-home', shown: true },
    { icon: Userinfo, name: 'Investors', path: 'users' },
    // { icon: ChartLine, name: 'Cap Table', path: 'cap-table', shown: true },
    { icon: Mysite, name: 'Company Details', path: 'company-details' },

    // { icon: Mysite, name: 'My Site', path: 'nft-site' },

    // { icon: RealEstate, name: 'Real Estate', path: 'all-real-estate', shown: true },
    // { icon: MintWizard, name: 'Digital Asset', path: 'asset-home' },

    // { icon: Coins, name: 'Payout Program', path: 'all-payout-programs' },
    // { icon: Event, name: 'Events', path: 'events' },
    // { icon: Campaigns2, name: 'Utility', path: 'utilities' },
    // { icon: Gift, name: 'Rewards', path: 'rewards' },
    // { icon: Analytics, name: 'Analytics', path: 'analytics' },
    // { icon: Key, name: 'Developer', path: 'developer', isRemoved: config.isMetaworks },
    // { icon: File, name: 'My Content', path: 'my-content' },
    // { icon: File, name: 'My Content ', path: 'my-content' },
  ];

  // const logged_in = true;

  return (
    <>
      <div className="flex h-[70px] justify-between border-b border-gray-300 pr-[30px]">
        <div className="box-border flex h-full w-[256px] items-center bg-card ">
          <div
            className="relative flex cursor-pointer items-center "
            onClick={() => {
              navigate('/home');
            }}
          >
            <div className="flex flex-col justify-center ">
              <img
                className="ml-[20px] h-[42px] rounded-md"
                // src={selectedProject?.logo_image_url ?? companyNameAndLogo.logo}
                src={config.projectLogoToDisplay}
              />
            </div>
            <div className="relative mb-[0px] ml-[15px] ">
              <h3 className=" flex items-center text-[0.9rem] font-semibold uppercase leading-[16px]">
                {/* {selectedProject ? selectedProject?.display_name : companyNameAndLogo.name} */}
                {companyNameAndLogo.name}
                {/* slate */}
              </h3>
              {isLoggedIn && (
                <p className="absolute bottom-0 left-0 m-0 mb-[-20px] w-40 text-xs text-gray-500">
                  Powered by Chainstarters
                </p>
              )}
            </div>
          </div>
        </div>
        {rootLocation === 'nft-site' && (
          <button
            className="z-10 ml-auto mr-[40px] mt-2 flex h-[52px] items-center justify-center rounded-[12px] border bg-card px-[40px] text-primary transition-colors duration-200 ease-in hover:bg-[#f3f3f3]"
            disabled={disableRestartDeployment}
            onClick={async () => {
              try {
                setDisableRestartDeployment(true);

                await client.mutate({
                  mutation: RestartUiDeploymentDocument,
                  context: {
                    clientName: ClientName.Marketplace,
                  },
                });
                toast({
                  title: 'Success!',
                  description: 'Your site has been successfully updated',
                  variant: 'success',
                });
                setTimeout(() => {
                  setDisableRestartDeployment(false);
                }, 3_000);
              } catch (e) {
                setDisableRestartDeployment(false);
                console.log(e);
                toast({
                  title: 'Error',
                  variant: 'destructive',
                  description: `${e}`,
                });
              }
            }}
          >
            <SymbolIcon className="mr-[9px] size-[16px] stroke-primary" />
            Update Site
          </button>
        )}
        <div className="flex items-center">
          <button>
            <SettingsImg
              className="mr-[10px] size-[28px] stroke-primary"
              onClick={() => {
                navigate(`/settings`);
              }}
            />
          </button>
          <SelectProjectDropdown />
        </div>
      </div>
      <div className="flex h-calc-100vh-70">
        <div className="relative box-border flex w-64 min-w-[256px] flex-col overflow-y-auto bg-card">
          <div className="flex grow flex-col justify-between border-r border-input pt-8">
            <div>
              {sideBarTabs.map((item, index) => {
                return (
                  <button
                    key={item.path}
                    className={cn(
                      `flex h-[65px] w-full items-center bg-transparent px-5 transition-colors duration-150 ease-in`,
                      rootLocation == item.path ? 'border-r-2 border-primary bg-primary-10' : '',
                    )}
                    onClick={() => {
                      navigate(`/${item.path}`);
                    }}
                  >
                    {
                      <item.icon
                        opacity={rootLocation !== item.path ? 0.4 : undefined}
                        fill={
                          item.name == 'Utility' || item.name == 'Real Estate'
                            ? 'hsl(var(--primary))'
                            : 'none'
                        }
                        stroke={rootLocation !== item.name ? 'hsl(var(--primary))' : 'none'}
                        className={rootLocation !== item.name ? 'stroke-primary' : ''}
                      />
                    }
                    <p
                      className={`ml-4 font-medium ${
                        rootLocation == item.path ? 'text-primary opacity-100' : 'opacity-40'
                      }`}
                    >
                      {item.name}
                    </p>
                  </button>
                );
              })}
            </div>
            <div>
              <button
                className="flex h-[65px] w-full items-center bg-transparent px-5"
                onClick={(e) => {
                  logout(navigate);
                }}
              >
                <LogoutIcon stroke="grey" />
                <p className="text-text ml-4 stroke-primary font-medium opacity-40">Log out</p>
              </button>
            </div>
          </div>
        </div>
        <div
          className="flex w-full flex-col items-center overflow-y-scroll bg-background px-[40px] py-[30px]"
          id="authLayoutContainerId"
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}

function AuthorizedLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [onBoardingOpen, setOnBoardingOpen] = useState<boolean>(false);

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const projPetNameFromUrl = searchParams.get(config.projectQueryParamsKey);
  const companyIdFromUrl = searchParams.get(config.companyQueryParamsKey);

  const marketplaceClientIsReady = useBoundStore(selectMarketplaceClientIsReady);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);
  const companyLoading = useBoundStore((state) => state.companySlice.companyLoading);
  const companies = useBoundStore((state) => state.companySlice.companies);

  const firebaseAuthChecked = useBoundStore((state) => state.authSlice.firebaseAuthChecked);

  const navigate = useNavigate();
  const isCheckingAuth = useBoundStore((state) => state.authSlice.isCheckingAuth);
  const authChecked = useBoundStore((state) => state.authSlice.authChecked);
  const user = useBoundStore((state) => state.authSlice.user);
  const [, rootLocation] = location.pathname.split('/');

  // console.debug('🦊 | AuthorizedLayout | isCheckingAuth:', { isCheckingAuth, authChecked, user });

  const projectSubscriptionRef = useRef<undefined | ObservableSubscription>();
  const fetchNextCollections = useBoundStore(
    (state) => state.collectionsSlice.fetchNextCollections,
  );
  const fetchMarketplaceInfo = useBoundStore(
    (state: StoreType) => state.projectSlice.fetchMarketplaceInfo,
  );

  const fetchNextAssets = useBoundStore((state) => state.assetsSlice.fetchNextAssets);
  const fetchPayoutPrograms = useBoundStore(
    (state) => state.payoutProgramsSlice.fetchPayoutPrograms,
  );

  // hooks
  useRenewAdminTokenSetup();

  // useEffect(() => {
  //   const testCall = async () => {
  //     const {
  //       data: { getAllNfts },
  //     } = await client.query({
  //       query: GetAllNfts_ExampleDocument,
  //       context: { clientName: ClientName.Marketplace },
  //       variables: { getAllNftsInput: { batch_size: 30, page_number: 0 } },
  //     });
  //     console.debug('🦊 | testCall | getAllNfts:', getAllNfts);
  //   };
  //   testCall();
  // }, []);

  const { pathname } = useLocation();

  const handleRenderLayout = useCallback(() => {
    const path = `/${pathname.split('/')[1]}`;
    switch (path) {
      case '/create-collection':
      case '/edit-collection':
      case '/create-asset':
      case '/edit-asset':
      case '/create-event':
      case '/create-event-pass':
      case '/create-rewards':
      case '/create-exclusive-video':
      case '/create-real-estate':
      case '/create-company':
      // case '/create-token':
      case '/edit-real-estate':
        return <CreateLayout pathname={path} />;
      default:
        return <DashboardLayout />;
    }
  }, [pathname]);

  const handleUnsubscribe = () => {
    try {
      if (projectSubscriptionRef.current) {
        console.debug('🦊 | handleUnsubscribe | calling unsubscribe');
        projectSubscriptionRef.current.unsubscribe();
      }
    } catch (err) {
      console.error('🦊 | handleUnsubscribe | err:', err);
    }
  };

  useEffect(() => {
    // must wait for auth?.currentUser (for fetching adminToken) by waiting for firebaseAuthChecked
    // or if this doesn't work, can try to use waitForFirebaseCurrentUser
    if (firebaseAuthChecked) {
      reviveAuth().then((sub) => {
        projectSubscriptionRef.current = sub;
      });
    }
  }, [firebaseAuthChecked]);

  /* *************** change query params based on selectedProject *************** */
  useEffect(() => {
    if (!selectedCompany?.id) return;
    if (
      (!companyIdFromUrl && selectedCompany?.id) ||
      (Boolean(companyIdFromUrl) && companyIdFromUrl !== selectedCompany?.id)
    ) {
      // vv this setSearchParams doesn't reload the page
      setSearchParams(
        { [config.companyQueryParamsKey]: selectedCompany?.id }, // COMEBACKTO
        { replace: true }, // The replace: true option prevents adding a new entry to the browser’s history stack.
      );
      // have to reload the page so it refetch everything from the right project
    }
  }, [selectedCompany, companyIdFromUrl]);
  /**********************************************************************************/

  useEffect(() => {
    // NOTE: have to wait for marketplaceClientIsReady, otherwise the apolloClient won't work
    if (marketplaceClientIsReady) {
      fetchNextCollections();
      fetchNextAssets();
      fetchPayoutPrograms();
      fetchMarketplaceInfo();
    }
    // on unmount
    return () => {
      if (marketplaceClientIsReady) handleUnsubscribe();
    };
  }, [marketplaceClientIsReady]);

  useEffect(() => {
    if (!companyLoading && companies.length === 0) {
      setOnBoardingOpen(true);
    }
  }, [companyLoading]);

  useEffect(() => {
    console.debug('😶 onload authorized-layout');
    return () => {
      console.debug('😶 unmount authorized-layout');
    };
  }, []);

  if (authChecked && !isCheckingAuth && !user) {
    console.debug('navigating to /signin before logout');
    logout();
    console.debug('navigating to /signin');
    return <Navigate to="/signin" replace />;
  } else if (Boolean(user) && pathname === '/') {
    return <Navigate to="/home" replace />;
  }

  return (
    <div className="flex h-screen flex-col">
      {handleRenderLayout()}
      {config.showTokenExpiresIn && <DisplayTokenExpiresAt />}
      {onBoardingOpen && <OnboardingModal open={onBoardingOpen} setOpen={setOnBoardingOpen} />}
    </div>
  );
}

function DisplayTokenExpiresAt(): JSX.Element {
  const tokenExpiresAt = useBoundStore((state) => state.authSlice.tokenExpiresAt);
  const refetchingAdminToken = useBoundStore((state) => state.authSlice.refetchingAdminToken);

  // Convert the Unix timestamp to a human-readable format
  const formattedTokenExpiresAt = tokenExpiresAt
    ? format(new Date(tokenExpiresAt * 1000), 'yyyy-MM-dd HH:mm:ss')
    : 'N/A'; // Fallback if tokenExpiresAt is not available

  return (
    <div className="fixed left-0 top-0 ml-2 text-xs">{`admin token expires at: ${formattedTokenExpiresAt} (refresh ${config.tokenRefreshThreshold} mins before expires) ${
      refetchingAdminToken ? 'refetching...' : ''
    }`}</div>
  );
}

function SelectProjectDropdown() {
  const setIsLoading = useBoundStore((state) => state.loadingOverlaySlice.setIsLoading);
  const projects = useBoundStore((state) => state.projectSlice.projects);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const projectsOptions = useMemo(
    () => projects?.map((p) => ({ value: p.project_name, label: p.display_name })),
    [projects],
  );

  const companies: Company[] = useBoundStore.getState().companySlice.companies;
  const selectedCompany = useBoundStore((state) => state.companySlice.selectedCompany);
  const companiesOptions = useMemo(
    () => companies?.map((comp) => ({ value: comp.id, label: comp.legal_name })),
    [companies],
  );
  // local state only, to show the name changes while waitinfg for handleSelectProject which has slow api call
  const [justSelectedProject, setJustSelectedProject] = useState<string>(
    selectedProject?.project_name ?? '',
  );
  const [justSelectedCompany, setJustSelectedCompany] = useState<string>(selectedCompany?.id ?? '');

  useEffect(() => {
    // marketplaceClientIsReady
  }, []);

  return (
    <SelectorDropdown
      name={'companySelect'}
      label={''}
      selected={justSelectedCompany || selectedCompany?.id}
      options={companiesOptions}
      underText={''}
      isError={false}
      required={false}
      callOnBlur={async ({ companySelect }) => {
        console.log({ companySelect });
        setIsLoading(true);
        await handleSelectProject(companySelect);
        setIsLoading(false);
        setJustSelectedCompany('');
      }}
      onSelect={(company_id) => {
        console.log({ company_id });
        setJustSelectedCompany(company_id);
      }}
      className="h-[42px] w-[250px] rounded-[22px] bg-card text-base"
    />
  );
}

export default AuthorizedLayout;
