import { Company } from '@/lib/__generated__/marketplace/graphql';
import { getUserCompanies } from '@/lib/api/query';
import { lens } from '@dhmk/zustand-lens';

const initialCompanySliceState: CompanySliceState = {
  companies: [],
  selectedCompany: null,
  companyLoading: true,
};

export const companySlice = lens<CompanySlice>(
  (set, get): CompanySlice => ({
    ...initialCompanySliceState,
    fetchCompanies: async () => {
      try {
        const getUserCompaniesRes = await getUserCompanies();
        console.log({ getUserCompaniesRes });
        set({ companies: getUserCompaniesRes, companyLoading: false });
        return getUserCompaniesRes;
      } catch (getUserCompaniesErr) {
        console.log({ getUserCompaniesErr });
        set({ companyLoading: false });
      }
    },
    setSelectedCompany: (selectedCompany) => {
      set({ selectedCompany });
    },
    addCompany: (comp) => {
      const companies = get().companies;
      const selectedCompany = get().selectedCompany;
      set({ companies: [...companies, comp] });
      if (selectedCompany == null) {
        set({ selectedCompany: comp });
      }
    },
    reset: () => {
      set({
        companies: [],
        selectedCompany: null,
        companyLoading: true,
      });
    },
  }),
);

interface CompanySliceState {
  companies: any[];
  companyLoading: boolean;
  selectedCompany: null | Partial<Company>;
}

interface CompanySliceActions {
  fetchCompanies: () => void;
  setSelectedCompany: (selectedCompany) => void;
  addCompany: (comp) => void;
  reset: () => void;
}

export type CompanySlice = CompanySliceState & CompanySliceActions;
